import { Doc } from './doc'
import { fuego } from '../firebase/fuego'
import { SmartOmit } from '../types/smart-omit'
import { DeveloperSchema } from '../schema/developer'
import { Server } from './server'

export class Developer {
	public id: string
	constructor({ id }: { id: string }) {
		this.id = id
	}

	static collection = 'developers'
	get path() {
		return `${Developer.collection}/${this.id}`
	}

	create(
		developer: SmartOmit<
			DeveloperSchema,
			'lastUpdated' | 'phoneNumber' | 'createdAt'
		>
	) {
		try {
			const { currentUser } = fuego.auth()
			if (currentUser) {
				// const collection = fuego.db
				// 	.doc(this.path)
				// 	.set(developer, { merge: true })
				const server = Server.post('createDeveloper', developer)
				const auth = fuego
					.auth()
					.currentUser?.updateProfile({
						displayName: developer.displayName.trim(),
					})
					.then(() => {
						console.log('user name updated')
						return fuego.auth().currentUser?.reload()
					})
				return Promise.all([server, auth])
			}
			throw new Error(
				'Error in Developer class`s create() function. Tried to update the user, but the currentUser field in firebase auth was null.'
			)
		} catch (e) {
			console.error(e)
		}
	}
	edit(
		developer: Partial<SmartOmit<DeveloperSchema, 'lastUpdated' | 'createdAt'>>
	) {
		try {
			return fuego.db
				.doc(this.path)
				.set({ ...developer, lastUpdated: new Date() }, { merge: true })
			throw new Error(
				'Error in Developer class`s create() function. Tried to update the user, but the currentUser field in firebase auth was null.'
			)
		} catch (e) {
			console.error(e)
		}
	}
}
