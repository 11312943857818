import React, { useMemo } from 'react'
import { useAuthHistory } from '../../../hooks/use-auth-history'
import AuthHistoryItem from '../../../components/AuthHistoryItem'
import { ActivityIndicator, View, StyleSheet } from 'react-native'
import { H1 } from '../../../packages/doorman_native/src'
import { P } from '@expo/html-elements'
import CardSection from '../../../components/Card-Section'
import { ProjectStackFeatures } from '../../../navigation/views/stacks/Project-Stack/project-stack-features'
import { optimizeHeavyScreen } from 'react-navigation-heavy-screen'

type Props = {
	publicProjectId: string
}

const AuthHistory = (props: Props) => {
	const { publicProjectId } = props

	const { data, loading } = useAuthHistory({ publicProjectId })
	const list = useMemo(
		() => data.map(sms => <AuthHistoryItem {...sms} key={sms.id} />),
		[data]
	)

	const loadingOrList = () => (loading ? <ActivityIndicator /> : list)

	return (
		<View>
			<CardSection
				{...ProjectStackFeatures.Debugger}
				title="SMS History Debugger 🐛"
				open
				hideArrow
			>
				<P>Texts sent by your users show up below in real-time.</P>
				{loadingOrList()}
			</CardSection>
		</View>
	)
}

export default React.memo(optimizeHeavyScreen(AuthHistory))

const styles = StyleSheet.create({
	banner: {
		marginHorizontal: 8,
	},
})
