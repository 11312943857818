import { useNavigation } from 'react-navigation-hooks'
import { NavigationPaths } from '../paths/index'

export const useNavigateToCreateProject = () => {
	const { navigate } = useNavigation()

	return {
		navigate: (params?: { firstProject: boolean }) =>
			navigate(NavigationPaths.CreateProject, params),
	}
}

export const useCreateProjectParams = () => {
	const { getParam } = useNavigation()

	const firstProject: boolean | undefined = getParam('firstProject')

	return {
		firstProject,
	}
}
