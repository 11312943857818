import React from 'react'
import { Row } from '@nandorojo/bootstrap'
import { View, Text } from 'react-native'
import { colors } from '../style/colors'
import * as Animatable from 'react-native-animatable'

type Props = {}

const RealTimeMonitor = (props: Props) => {
	const {} = props
	return (
		<Row alignItems="center">
			<View
				style={{
					height: 16,
					width: 16,
				}}
			>
				<Animatable.View
					style={{
						backgroundColor: colors().green.shadowColor,
						height: 16,
						width: 16,
						borderRadius: 8,
						position: 'absolute',
						top: 0,
						left: 0,
					}}
					animation="zoomIn"
					iterationCount="infinite"
					direction="alternate"
				/>

				<View
					style={{
						backgroundColor: colors().green.color,
						height: 6,
						width: 6,
						borderRadius: 3,
						position: 'absolute',
						top: 15 / 2 - 6 / 2,
						left: 15 / 2 - 6 / 2,
					}}
				/>
			</View>
			<Text style={{ color: colors().green.color, paddingLeft: 8 }}>
				Monitoring in real-time
			</Text>
		</Row>
	)
}

export default RealTimeMonitor
