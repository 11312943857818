export const colors = () => ({
	green: {
		color: '#17694c',
		shadowColor: '#CBF4C9',
	},
	blue: {
		color: '#5357B3',
		shadowColor: '#D7EBFF',
	},
	red: {
		color: '#B5466B',
		shadowColor: '#FDE2DD',
	},
	yellow: {
		color: '#AD5E2E',
		shadowColor: '#F6E6B9',
	},
	lightGray: {
		color: '#F7FAFC',
		shadowColor: '',
	},
})
