import { Fuego } from '@nandorojo/fuego'

export const fuego = new Fuego({
	apiKey: 'AIzaSyDS_8UJvGCBFSxOPrElZK2aQNpjwxTHv3Y',
	authDomain: 'doorman-e664d.firebaseapp.com',
	databaseURL: 'https://doorman-e664d.firebaseio.com',
	projectId: 'doorman-e664d',
	storageBucket: 'doorman-e664d.appspot.com',
	messagingSenderId: '162864930911',
	appId: '1:162864930911:web:7222a803461c0ab7913bea',
	// measurementId: 'G-D4S75H6VBX',
})
