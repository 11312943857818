import React from 'react'
import { FireDoc } from '../../types/document'
import { ProjectSchema } from '../../schema/project'
import { Card, Chip, DefaultTheme } from 'react-native-paper'
import { StyleSheet, View, Text } from 'react-native'
import { colors } from '../../style/colors'
import { Pre, Strong } from '@expo/html-elements'
import moment from 'moment'
import { ProjectsStackParams } from '../../navigation/views/stacks/Projects-Stack'
import DeployedChip from '../DeployedChip'

type Props = FireDoc<ProjectSchema> & {
	index: number
	fullWidth?: boolean
	onPress: (info: ProjectsStackParams['Project']) => void
}

const ProjectPreview = (props: Props) => {
	const {
		id,
		createdAt,
		developers,
		lastDeployment,
		lastUpdated,
		internalName,
		publicName,
		firebaseProjectId,
		fullWidth = false,
		onPress,
	} = props

	const isWorking = !!lastDeployment?.isWorking

	let date = `Created ${moment(createdAt).calendar()}`
	if (lastDeployment?.lastUpdated) {
		date = `Updated ${moment(lastDeployment.lastUpdated).calendar()}`
	}

	const deploymentStatus = isWorking
		? '🥳 Deployed'
		: '⛔️ Deployment Incomplete'

	const { red, green } = colors()

	return (
		<View style={[styles.container]}>
			<Card
				onPress={() => onPress({ id, publicName, internalName })}
				elevation={5}
			>
				<Card.Title
					title={internalName || publicName}
					titleStyle={{ color: DefaultTheme.colors.primary }}
					subtitle={
						<Text>
							<Strong>{id}</Strong>
							{!!internalName && ` | ${publicName}`}
						</Text>
					}
					right={() => <DeployedChip isWorking={isWorking} />}
					rightStyle={{ marginRight: 16, alignItems: 'flex-start' }}
				/>
				<View style={styles.row}>
					<Text style={{ margin: 8 }}>{date}</Text>
				</View>
			</Card>
		</View>
	)
}

export default React.memo(ProjectPreview)

const styles = StyleSheet.create({
	container: {
		paddingVertical: 12,
		width: '100%',
	},
	row: {
		flexDirection: 'row',
		margin: 8,
		flexWrap: 'wrap',
	},
})
