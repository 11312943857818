import React from 'react'
import 'prismjs'
import './theme.css'

import Code from 'react-prism'
import {
	TouchableOpacity,
	Clipboard,
	StyleSheet,
	Text,
	View,
} from 'react-native'

type Props = {
	children: string
	language?: string
}

export default function CodeBlock(props: Props) {
	const { children, language = 'javascript' } = props
	return (
		<View>
			<Code component="pre" className={`language-${language}`}>
				{children}
			</Code>
			<TouchableOpacity
				onPress={() => {
					Clipboard.setString(children)
					alert('Copied to clipboard 😇')
				}}
				style={styles.copy}
			>
				<Text style={styles.copyText}>Copy</Text>
			</TouchableOpacity>
		</View>
	)
}

const styles = StyleSheet.create({
	copy: {
		position: 'absolute',
		// bottom: 0,
		right: 4,
		top: 4,
		justifyContent: 'center',
	},
	copyText: {
		borderWidth: 1,
		borderColor: 'green',
		paddingVertical: 4,
		paddingHorizontal: 8,
		borderRadius: 4,
		backgroundColor: 'white',
		color: 'green',
	},
})
