import { useFuego } from '@nandorojo/fuego'
import { FireDoc } from '../types/document'
import { useMemo } from 'react'
import { empty } from '../utils/empty'
import { BillingMonthSchema } from '../schema/billing-month'
import { BillingMonth } from '../api/billing-month'

export const useBillingMonths = ({
	publicProjectId,
}: {
	publicProjectId: string
}) => {
	const query = useMemo<Parameters<typeof useFuego>[0]>(
		() => ({
			path: BillingMonth.getCollectionPath({ publicProjectId }),
			orderBy: ['createdAt', 'desc'],
		}),
		[publicProjectId]
	)
	const { data, ...request } = useFuego<FireDoc<BillingMonthSchema<true>>>(
		query
	)
	const parsedData = useMemo(
		() =>
			(data as FireDoc<BillingMonthSchema<true>>[])?.map(
				BillingMonth.parseForRender
			) ?? empty.array,
		[data]
	)
	return { data: parsedData, ...request }
}
