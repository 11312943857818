import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// import Checkout from './Checkout.web'
import CardForm from './Card-Form'
import './styles.css'
import { stripeKey } from './stripe-config'

const stripePromise = loadStripe(stripeKey)

export default function Stripe({
	publicProjectId,
	isPaidProject,
}: {
	publicProjectId: string
	isPaidProject: boolean
}) {
	return (
		<Elements stripe={stripePromise}>
			<CardForm
				publicProjectId={publicProjectId}
				isPaidProject={isPaidProject}
			/>
		</Elements>
	)
}
