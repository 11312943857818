import { useState, useEffect, useRef } from 'react'
import { Payment } from '../../api/payment'

/**
 * Request setup intent from stripe to start adding a new method.
 */
export const useSetupIntentId = ({
	publicProjectId,
}: {
	publicProjectId: string
}) => {
	const [setupIntent, setSetupIntent] = useState<string | null>(null)
	const [loading, setLoading] = useState(false)
	const [refetching, setRefetching] = useState(false)

	const refetch = async (refetching?: boolean) => {
		try {
			setLoading(true)
			if (refetching) setRefetching(refetching)
			const { setupIntentId, message, success } = await Payment.requestIntent({
				publicProjectId,
			})
			setLoading(false)
			if (!success) throw new Error(message)
			setSetupIntent(setupIntentId)
		} catch (e) {
			setLoading(false)
			console.error('Error in useSetupIntentId', e)
		}
	}
	const refetchRef = useRef(refetch)
	useEffect(() => {
		refetchRef.current = refetch
	})

	const mounted = useRef(false)

	useEffect(() => {
		refetchRef.current(mounted.current)
		mounted.current = true
	}, [publicProjectId])

	return {
		setupIntent,
		loading,
		refetch,
		refetching: refetching && loading,
		loadingInitially: loading && !refetching,
	}
}
