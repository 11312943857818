import { useFuego } from '@nandorojo/fuego'
import { useMemo, useState, useCallback } from 'react'
import { CallSchema } from '../schema/error'
import { Call } from '../api/call'
import { FireDoc } from '../types/document'
import { empty } from '../packages/doorman_native/src/utils/empty'

export const useProjectCalls = (info: { publicProjectId: string }) => {
	const [errorsOnly, setErrorsOnly] = useState(false)
	const { publicProjectId } = info
	const [limit, setLimit] = useState(10)

	const toggleErrorsOnly = useCallback(() => setErrorsOnly(state => !state), [])

	const paginate = useCallback(() => {
		setLimit(state => state + 20)
	}, [])
	const { data, ...response } = useFuego<FireDoc<CallSchema<true>>>(
		useMemo(() => {
			const where: Parameters<typeof useFuego>[0]['where'] = [
				['requestBody.publicProjectId', '==', publicProjectId],
				['internal', '==', false],
			]
			if (errorsOnly) {
				where.push(['isError', '==', true])
			}
			return {
				path: Call.collection,
				listen: true,
				orderBy: ['createdAt', 'desc'],
				limit,
				where,
			}
		}, [publicProjectId, errorsOnly, limit])
	)

	const parsed = useMemo(
		() =>
			data
				? (data as FireDoc<CallSchema<true>>[])?.map(Call.parseForRender)
				: empty.array,
		[data]
	)

	return { data: parsed, ...response, paginate, setErrorsOnly, errorsOnly }
}
