import React, { ComponentPropsWithoutRef, ReactNode, useState } from 'react'
import { Card } from 'react-native-paper'
import { Ionicons } from '@expo/vector-icons'
import Collapsible from 'react-native-collapsible'
import { StyleSheet, TouchableOpacity } from 'react-native'
import Circle from './Circle'
import { H2, H3 } from '@expo/html-elements'

type Props = {
	cardProps?: ComponentPropsWithoutRef<typeof Card>
	title: string
	/**
	 * Function called when the card is clicked.
	 *
	 * @param info.open boolean that tells you if the result is an open or closed item.
	 */
	onToggle?: (info: { open: boolean }) => void
	/**
	 * Item that tells you whether or not this item is open.
	 */
	open?: boolean
	children: ReactNode
	/**
	 * Ionicons icon name.
	 */
	icon?: string
	color?: string
	shadowColor?: string
	hideArrow?: boolean
	subtitle?: string
	right?: () => ReactNode
	/**
	 * If defined, the component handles its open/closed state internally. The value here indicates if it starts open or not.
	 * If undefined, the state is defined externally with the `open` and `onToggle` prop.
	 *
	 * Default: `undefined`.
	 */
	handleState?: 'open' | 'closed'
	/**
	 * How padded the content should be. Default: `8`.
	 */
	padding?: number
}

export default function CardSection(props: Props) {
	const {
		title,
		onToggle,
		open,
		children,
		cardProps = {},
		color,
		shadowColor = '',
		icon,
		hideArrow = false,
		subtitle,
		right,
		handleState,
		padding = 16,
	} = props
	const [openState, setOpenState] = useState(handleState === 'open')

	const isOpen = handleState ? openState : open

	return (
		<Card elevation={3} {...cardProps} style={styles.container}>
			<TouchableOpacity
				onPress={() => {
					if (handleState) {
						setOpenState(state => !state)
					} else {
						onToggle?.({ open: !open })
					}
				}}
				disabled={!onToggle && !handleState}
			>
				<Card.Title
					title={title}
					left={() =>
						icon ? (
							<Circle color={shadowColor} size={40}>
								<Ionicons name={icon} size={25} color={color} />
							</Circle>
						) : null
					}
					subtitle={subtitle}
					right={() =>
						right?.() ??
						(!hideArrow && (
							<Ionicons
								name={isOpen ? 'ios-arrow-up' : 'ios-arrow-down'}
								size={30}
								style={{ paddingHorizontal: 16, paddingVertical: 8 }}
							/>
						))
					}
				/>
			</TouchableOpacity>

			<Collapsible collapsed={!isOpen} style={{ padding, paddingTop: 0 }}>
				{children}
			</Collapsible>
		</Card>
	)
}

const styles = StyleSheet.create({
	container: {
		marginBottom: 16,
	},
})
