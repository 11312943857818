import React, { ReactNode } from 'react'
import { FuegoProvider } from '@nandorojo/fuego'
import { FuegoProvider as Swr } from '@nandorojo/swr-firestore'
import { fuego } from '../firebase/fuego'

type Props = {
	children: ReactNode
}

const Provider = (props: Props) => {
	const { children } = props

	return (
		<FuegoProvider fuego={fuego}>
			<Swr fuego={fuego}>{children}</Swr>
		</FuegoProvider>
	)
}

export default Provider
