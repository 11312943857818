import React, { useEffect } from 'react'
import { ProjectSchema } from '../schema/project'
import { Card, Divider, ActivityIndicator } from 'react-native-paper'
import { P, Strong, A } from '@expo/html-elements'
import CardSection from '../components/Card-Section'
import { ProjectStackFeatures } from '../navigation/views/stacks/Project-Stack/project-stack-features'
import { fakeResize } from '../utils/fake-resize'
import { Text } from 'react-native'

type Props = {
  testNumbers: Required<ProjectSchema>['testNumbers']
  phoneNumber?: string
  loading: boolean
  appleCode?: string
  appleTestNumber?: string
}

const TestNumbers = (props: Props) => {
  const {
    testNumbers,
    phoneNumber,
    loading,
    appleCode,
    appleTestNumber,
  } = props

  useEffect(() => {
    fakeResize()
  }, [loading])

  return (
    <>
      <CardSection
        {...ProjectStackFeatures['Test Numbers']}
        title="Free test phone numbers"
        open
        hideArrow
      >
        <P>
          Test numbers allow you to try Doorman in development. You{' '}
          {`don't get charged for any texts sent to test numbers.`}
        </P>
        <P>
          When you use one of these numbers in your app, the 6-digit
          verification code will be sent to{' '}
          {!!phoneNumber ? (
            <Strong>{phoneNumber}</Strong>
          ) : (
            'your actual phone number.'
          )}
          .
        </P>
        <P>
          You can learn more about test numbers in our{' '}
          <A
            style={{ fontWeight: 'bold', color: 'green' }}
            href="https://docs.doorman.cool/introduction/free-test-numbers"
            target="_blank"
          >
            docs.
          </A>
        </P>
        {loading ? (
          <ActivityIndicator />
        ) : (
          testNumbers.map(testNumber => (
            <React.Fragment key={testNumber}>
              <Card.Title title={testNumber} key={testNumber} />
              <Divider />
            </React.Fragment>
          ))
        )}
      </CardSection>
      <CardSection
        icon="logo-apple"
        open
        hideArrow
        title="App Store Test Account"
      >
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <P>
              When you submit your app to the App Store, Apple might request
              that you give them a test account to sign in with.
            </P>
            <P>You can include the details below in your submission: </P>
            {!!(appleTestNumber && appleCode) ? (
              <>
                <Divider />
                <Card.Title
                  title={appleTestNumber}
                  right={() => <Text>Code: {appleCode}</Text>}
                  rightStyle={{ marginRight: 8 }}
                />
                <Divider />
              </>
            ) : (
              <Card.Title title="Apple code not found." />
            )}
            <P>
              You can learn more about deploying to the App Store in our{' '}
              <A
                style={{ fontWeight: 'bold', color: 'green' }}
                href="https://docs.doorman.cool/resources/deploying-to-the-app-store"
                target="_blank"
              >
                docs.
              </A>
            </P>
          </>
        )}
      </CardSection>
    </>
  )
}

export default TestNumbers
