import { useFuego } from '@nandorojo/fuego'
import { Project } from '../api/project'
import { ProjectSchema } from '../schema/project'
import { FireDoc } from '../types/document'
import { useMemo } from 'react'

export const useProject = ({ id }: { id: string }) => {
	const query = useMemo<Parameters<typeof useFuego>[0]>(
		() => ({
			path: new Project({ id }).path,
			listen: true,
		}),
		[id]
	)
	const { data, ...request } = useFuego<FireDoc<ProjectSchema<true>>>(query)

	const parsedData = useMemo(
		() => data && Project.parseForRender(data as FireDoc<ProjectSchema<true>>),
		[data]
	)
	return { data: parsedData, ...request }
}
