import { useState, useCallback } from 'react'
import { ApiSecret } from '../api/secret'
import { empty } from '../utils/empty'
import { Project } from '../api/project'

export const useCreateApiSecret = ({
	publicProjectId,
	isWorking,
}: {
	publicProjectId: string
	isWorking: boolean
}) => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<null | string>(null)
	const [apiSecret, setApiSecret] = useState<string>()
	const [status, setStatus] = useState<
		'none' | 'check-iams-success' | 'loading-check-iams'
	>('none')
	const [alreadyDeployed, setAlreadyDeployed] = useState(isWorking)

	const create = useCallback(
		async (info: { overrideKey?: boolean } = empty.object) => {
			setLoading(true)
			setError(null)
			try {
				const { overrideKey = false } = info

				let shouldCreateNewSecret = overrideKey

				// if we aren't overriding the key, check if IAMS is configured
				if (!overrideKey) {
					setStatus('loading-check-iams')
					const { isWorking, success, message } = await new Project({
						id: publicProjectId,
					}).verifyIamsComplete()
					if (!success) throw new Error(message)

					setStatus('check-iams-success')
					// show if IAMS is already deployed
					setAlreadyDeployed(isWorking)

					// if the deployment already worked, and we aren't overriding the existing secret key, stop here
					shouldCreateNewSecret = !isWorking
				}
				if (shouldCreateNewSecret) {
					// assuming either 1) isWorking is false or 2) we are overriding the key
					const { apiSecret } = await ApiSecret.create({ publicProjectId })
					setApiSecret(apiSecret)
				}
				setLoading(false)
			} catch (e) {
				setLoading(false)
				setError(null)
				setApiSecret(undefined)
				console.error('create-api-secret-error', e)
			}
		},
		[publicProjectId]
	)
	const createAndOverrideKey = useCallback(
		() => create({ overrideKey: true }),
		[create]
	)

	return {
		apiSecret,
		loading: loading || status === 'loading-check-iams',
		error,
		create,
		loadingIams: status === 'loading-check-iams',
		alreadyDeployed: alreadyDeployed || isWorking,
		createAndOverrideKey,
	}
}
