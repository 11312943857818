export const TextStyle = {
	h1: {
		fontSize: 26,
		fontWeight: 'bold' as 'bold',
		marginBottom: 16,
		marginTop: 8,
	},
	subtitle: {
		marginBottom: 16,
		fontSize: 16,
	},
	disclaimer: {
		color: 'gray',
		fontSize: 12,
	},
	error: {
		marginVertical: 8,
		padding: 8,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: 'red',
		color: 'red',
		backgroundColor: 'white',
	},
}
