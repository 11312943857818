export const calculateMonthlyRate = ({
	uniqueUsers = 0,
	smsCost = 0,
}: {
	uniqueUsers?: number
	smsCost?: number
}) => {
	let fixedCost = 10
	if (uniqueUsers > 1000) {
		fixedCost = 100
	} else if (uniqueUsers > 10000) {
		fixedCost = 500
	}

	return fixedCost + smsCost
}
