import { Log } from './api/log'
console.log({ devMode: __DEV__ }, AppVersion)

if (!__DEV__ && console) {
	console.disableYellowBox = true
	console.log = Log.log
	console.error = Log.error
	console.warn = Log.warn
}

import React from 'react'
import Providers from './providers'
import Navigator from './navigation'
import { enableScreens } from 'react-native-screens'
import { AppVersion } from '../app-version'

enableScreens()

const App = () => {
	return (
		<Providers>
			<Navigator />
		</Providers>
	)
}

export default App
