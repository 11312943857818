import { Doc } from './doc'
import { ProjectSchema } from '../schema/project'
import { fuego } from '../firebase/fuego'
import { SmartOmit } from '../types/smart-omit'
import { FireDoc } from '../types/document'
import { Server } from './server'

export class Project {
	public id: string
	constructor({ id }: { id: string }) {
		this.id = id
	}

	static collection = 'projects'
	/**
	 * Get the path for a specific project
	 */
	get path() {
		return `${Project.collection}/${this.id}`
	}

	static create({
		firebaseProjectId,
		internalName,
		publicName,
	}: SmartOmit<
		ProjectSchema,
		| 'lastDeployment'
		| 'lastUpdated'
		| 'developers'
		| 'createdAt'
		| 'testNumbers'
		| 'defaultPhoneNumber'
	>) {
		const { currentUser } = fuego.auth()
		if (!currentUser?.uid)
			throw new Error('missing user uid for Project.create')

		// const document: SmartOmit<ProjectSchema, 'createdAt' | 'developers'> = {
		// 	...project,
		// 	internalName: project.internalName.trim(),
		// 	publicName: project.publicName.trim(),
		// 	firebaseProjectId: project.firebaseProjectId.trim(),
		// 	developers: [currentUser.uid],
		// }
		return Server.post('createProject', {
			firebaseProjectId: firebaseProjectId.trim(),
			internalName: internalName.trim(),
			publicName: publicName.trim(),
		})
		// return fuego.db.collection(Project.collection).add(document)
	}

	static parseForRender(
		project: FireDoc<ProjectSchema<true>>
	): FireDoc<ProjectSchema> {
		let lastDeployment: ProjectSchema['lastDeployment']

		if (project.lastDeployment) {
			lastDeployment = {
				...project.lastDeployment,
				lastUpdated: project.lastDeployment.lastUpdated?.toDate(),
				createdAt: project.lastDeployment.lastUpdated?.toDate(),
			}
		}
		return {
			...project,
			createdAt: project.createdAt.toDate(),
			lastUpdated: project.lastUpdated?.toDate(),
			lastDeployment,
		}
	}
	cancelSubscription() {
		return Server.post('cancelSubscription', {
			publicProjectId: this.id,
		})
	}
	// editMonthlySmsBudgetLimit() {}
	async verifyIamsComplete() {
		return Server.post('verifyIamsComplete', {
			publicProjectId: this.id,
		})
	}
}
