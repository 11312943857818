import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import CodeBlock from '../../components/Code-Block'
import { P, H2, Strong, Pre, H3, A, I, EM } from '@expo/html-elements'
import { Divider, ActivityIndicator, Button, Colors } from 'react-native-paper'
import { useCreateApiSecret } from '../../hooks/use-create-api-secret'
import CLI from './CLI'
import CreateApiSecret from './Create-Api-Secret'
import ConfirmDeployment from './Confirm-Deployment'
import * as Animatable from 'react-native-animatable'
import { UL, LI } from '@expo/html-elements'

import { colors } from '../../style/colors'
import CardSection from '../../components/Card-Section'
import { ProjectStackFeatures } from '../../navigation/views/stacks/Project-Stack/project-stack-features'
import { Row } from '@nandorojo/bootstrap'
import { ProjectSchema } from '../../schema/project'
import { empty } from '../../utils/empty'

type Props = {
	id: string
	firebaseProjectId: string
	publicName: string
	loading: boolean
	publicProjectId: string
	isPaidProject: boolean
	navigateToTestNumbers: () => void
	navigateToBilling: () => void
	lastDeployment?: ProjectSchema['lastDeployment']
	isWorking: boolean
	iamWorking: boolean
	checkForIams: () => void
	checkForIamsLoading: boolean
}

export default function AppInstallation(props: Props) {
	const {
		id,
		firebaseProjectId,
		isPaidProject,
		loading,
		publicProjectId,
		navigateToTestNumbers,
		navigateToBilling,
		lastDeployment = empty.object,
		iamWorking,
		isWorking,
		checkForIams,
		checkForIamsLoading,
	} = props

	const {
		create,
		loading: secretLoading,
		apiSecret,
		alreadyDeployed,
		createAndOverrideKey,
	} = useCreateApiSecret({
		publicProjectId,
		isWorking,
	})

	return (
		<>
			<CardSection
				hideArrow
				{...ProjectStackFeatures.Install}
				title="Installation Overview"
				open
				padding={16}
				shadowColor={colors().green.shadowColor}
			>
				<P>There are few simple steps to getting Doorman deployed:</P>
				<UL>
					<LI>1) Configure Firebase.</LI>
					<LI>2) Run our one-step CLI.</LI>
					<LI>3) Install our React Native library.</LI>
					<LI>4) Import Doorman into your app.</LI>
				</UL>
				<P>
					Set up will take about <Strong>4 minutes</Strong>. 🏄🏾‍♂️
				</P>
				<P>
					You can also refer to our{' '}
					<A
						style={{ fontWeight: 'bold', color: 'green' }}
						href="https://docs.doorman.cool"
						target="_blank"
					>
						documentation
					</A>
					.
				</P>
			</CardSection>
			<CardSection
				padding={16}
				{...colors().red}
				shadowColor="transparent"
				title="1. Configure Firebase IAM Permissions"
				icon="ios-flame"
				handleState="closed"
			>
				<P>
					Before you can use Doorman, you have to enable what Firebase calls{' '}
					<Strong>IAM Permissions</Strong> for Firebase project{' '}
					{firebaseProjectId}.
				</P>
				<P>
					Read our quick{' '}
					<A
						href="https://docs.doorman.cool/introduction/getting-started/configure-firebase"
						target="_blank"
						style={styles.a}
					>
						tutorial
					</A>{' '}
					on how to enable <Strong>IAM Permissions</Strong>, and return to this
					screen once {`it's`} done.
				</P>
				<View style={[styles.row, { marginVertical: 8 }]}>
					<A
						href="https://docs.doorman.cool/introduction/getting-started/configure-firebase"
						target="_blank"
					>
						<Button mode="contained">Open Tutorial</Button>
					</A>
				</View>
				<P>
					<EM>
						This tutorial assumes you have already set up a Firebase auth
						account.
					</EM>
				</P>
			</CardSection>
			<CardSection
				title="2: Run the CLI"
				icon="md-business"
				color={colors().blue.color}
				handleState="closed"
				padding={16}
				shadowColor="transparent"
			>
				{loading ? (
					<ActivityIndicator />
				) : (
					<>
						<CreateApiSecret
							loading={secretLoading}
							create={create}
							apiSecret={apiSecret}
							alreadyDeployed={alreadyDeployed}
							createAndOverrideKey={createAndOverrideKey}
							iamWorking={iamWorking}
							// isWorking={isWorking}
							checkForIams={checkForIams}
							checkForIamsLoading={checkForIamsLoading}
						/>
						{!!apiSecret && (
							<CLI
								firebaseProjectId={firebaseProjectId}
								apiSecret={apiSecret}
							/>
						)}
					</>
				)}
				{!!apiSecret && (
					<>
						<View
							style={{ flexDirection: 'row', justifyContent: 'space-between' }}
						>
							<H3 style={{ marginTop: 0, paddingTop: 16 }}>
								2.3 Monitor deployment status
							</H3>
							<View style={{ flexDirection: 'row', alignItems: 'center' }}>
								<View
									style={{
										height: 16,
										width: 16,
									}}
								>
									<Animatable.View
										style={{
											backgroundColor: colors().green.shadowColor,
											height: 16,
											width: 16,
											borderRadius: 8,
											position: 'absolute',
											top: 0,
											left: 0,
										}}
										animation="zoomIn"
										iterationCount="infinite"
										direction="alternate"
									/>

									<View
										style={{
											backgroundColor: colors().green.color,
											height: 6,
											width: 6,
											borderRadius: 3,
											position: 'absolute',
											top: 15 / 2 - 6 / 2,
											left: 15 / 2 - 6 / 2,
										}}
									/>
								</View>
								<Text style={{ color: colors().green.color, paddingLeft: 8 }}>
									Monitoring in real-time
								</Text>
							</View>
						</View>

						<ConfirmDeployment
							// isWorking={deploymentIsWorking}
							// lastDeployedAt={lastDeployedAt}
							loading={loading}
							// lastUpdated={lastDeployedAt}
							{...lastDeployment}
						/>
					</>
				)}
			</CardSection>
			<CardSection
				handleState="closed"
				shadowColor=""
				icon="md-settings"
				title="3: Install in React Native"
				color={colors().green.color}
			>
				<H3>If you are using an Expo managed project:</H3>
				<P>{`Install our react native library and its peer dependencies:`}</P>
				<CodeBlock>{`expo install react-native-doorman react-native-reanimated firebase react-native-gesture-handler`}</CodeBlock>
				<H3>If you are using Expo bare or React Native without Expo:</H3>
				<P>
					Follow instructions for the{' '}
					<A
						style={{ color: 'green', fontWeight: 'bold' }}
						href="https://docs.doorman.cool/introduction/install#if-you-arent-using-expo"
						target="_blank"
					>
						React Native bare installation docs.
					</A>
				</P>
			</CardSection>
			<CardSection
				handleState="closed"
				icon="md-phone-portrait"
				title="4: Import into your app"
				color={Colors.blue600}
			>
				<P>
					The simplest way to use Doorman is to wrap your root{' '}
					<Pre style={{ fontWeight: 'bold' }}>App</Pre> component with the{' '}
					<Pre style={{ fontWeight: 'bold' }}>withPhoneAuth</Pre> higher order
					component.
				</P>
				<CodeBlock>
					{`
import React from 'react'
import { Text } from 'react-native'
import { withPhoneAuth } from 'react-native-doorman'

import firebase from 'firebase/app'
import 'firebase/auth'

// 1. initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(YOUR_FIREBASE_CONFIG)
}

// 2. create your app
const App = () => (
	<Text 
	   onPress={() => firebase.auth().signOut()} 
	   style={{ paddingTop: 300, color: 'blue', fontSize: 24 }}
	 >
	   This app is authed!!
	 </Text>
)

// 3. give your app phone auth 🎉
export default withPhoneAuth(App, {
  doorman: {
    publicProjectId: '${id}'
  }
})
						`}
				</CodeBlock>
				<P
					style={{
						padding: 16,
						backgroundColor: colors().green.shadowColor,
						borderRadius: 5,
					}}
				>
					✅ Your app now has phone authentication! Check out our{' '}
					<Strong>
						<A
							href="https://docs.doorman.cool"
							style={{ color: 'blue' }}
							target="_blank"
						>
							documentation
						</A>
					</Strong>{' '}
					for more.
				</P>
				{!isPaidProject && (
					<>
						<P
							style={{
								padding: 16,
								backgroundColor: colors().red.shadowColor,
								borderRadius: 5,
								marginVertical: 8,
							}}
						>
							🚨 You are on a free account, so you can only use test phone
							numbers.{' '}
							<Strong>
								Add a payment method to send to real phone numbers.
							</Strong>
						</P>
						<Row justifyContent="center">
							<Button
								color={Colors.deepPurpleA700}
								mode="contained"
								onPress={navigateToBilling}
								style={{ marginRight: 8 }}
							>
								Add payment method
							</Button>
							<Button
								color="black"
								mode="contained"
								onPress={navigateToTestNumbers}
							>
								Go To Test Numbers
							</Button>
						</Row>
					</>
				)}
				<P>
					If you want customization, you can see the docs for{' '}
					<A
						style={styles.a}
						href="https://docs.doorman.cool/react-native/withphoneauth"
						target="_blank"
					>
						<Pre style={{ fontWeight: 'bold' }}>withPhoneAuth</Pre>
					</A>
					, such as a loading component, splash screen, and more.
				</P>
				<P>
					Want <I>even more customization?</I> No problem! 🥳 This higher order
					component is not the only way to add Doorman to your app. You can also
					use our <Strong>Component-based API.</Strong> This is the most typical
					option (Doorman is made with it!), so check out the{' '}
					<A
						href="https://docs.doorman.cool/react-native/auth-flow"
						target="_blank"
						style={{ color: 'green', fontWeight: 'bold' }}
					>
						docs for that
					</A>
					.
				</P>
			</CardSection>
		</>
	)
}

const styles = StyleSheet.create({
	a: {
		fontWeight: 'bold',
		color: 'green',
	},
	row: {
		flexDirection: 'row',
	},
	card: {
		paddingHorizontal: 8,
	},
})
