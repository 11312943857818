import { useState } from 'react'
import { Payment } from '../../api/payment'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'

export const useAddStripePaymentMethod = ({
	publicProjectId,
	setupIntent,
}: {
	publicProjectId: string
	setupIntent: string | null
}) => {
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState<string | null>()

	const stripe = useStripe()
	const elements = useElements()

	const handleSubmit = async (event: { preventDefault(): void }) => {
		event.preventDefault()

		if (!stripe || !elements || !setupIntent) {
			return
		}
		try {
			const card = elements.getElement(CardElement)
			if (card) {
				setLoading(true)
				setError(null)
				const result = await stripe.confirmCardSetup(setupIntent, {
					// eslint-disable-next-line @typescript-eslint/camelcase
					payment_method: {
						card,
					},
				})

				if (result.error) {
					// Display result.error.message in your UI.
					console.error('card form error: ', result.error)
					setLoading(false)
					// throw new Error(result.error.message)
					setError(result.error.message)
				} else {
					setError(null)
					// The setup has succeeded. Display a success message and send
					// result.setupIntent.payment_method to your server to save the
					// card to a Customer
					if (result.setupIntent?.payment_method) {
						addMethod({
							paymentMethod: result.setupIntent?.payment_method,
						})
					}
				}
			} else {
				throw new Error('Could not verify card. Please try agian.')
			}
		} catch (e) {
			// setError(e)
			console.error('Error uploading payment method in card form:', e)
		}
	}

	const addMethod = async ({ paymentMethod }: { paymentMethod: string }) => {
		try {
			setLoading(true)
			console.log('adding payment method...')
			const { success, message } = await Payment.addMethod({
				publicProjectId,
				paymentMethod,
			})

			if (!success) throw new Error(message)
			setSuccess(true)

			setLoading(false)
		} catch (e) {
			setLoading(false)

			console.error('Error in useAddStripePaymentMethod -> addMethod()', e)
		}
	}

	return {
		addMethod,
		loading,
		setLoading,
		success,
		error,
		setError,
		handleSubmit,
	}
}
