import { CallSchema } from '../schema/error'
import { FireDoc } from '../types/document'

/**
 * Get a list of server calls made to Doorman.
 */
export class Call {
	static collection = 'calls'
	static parseForRender(error: FireDoc<CallSchema<true>>): FireDoc<CallSchema> {
		return {
			...error,
			lastUpdated: error.lastUpdated?.toDate(),
			createdAt: error.createdAt?.toDate(),
		}
	}
}
