import React, { ReactNode, useMemo, ComponentPropsWithoutRef } from 'react'
import {
	ScrollView,
	View,
	ViewStyle,
	StyleProp,
	StyleSheet,
} from 'react-native'
import { empty } from '../utils/empty'

type Props = {
	header?: () => ReactNode
	children: ReactNode
	containerStyle?: StyleProp<ViewStyle>
	scrollViewProps?: Omit<
		ComponentPropsWithoutRef<typeof ScrollView>,
		'children' | 'style'
	>
	scrollViewStyle?: StyleProp<ViewStyle>
	backgroundColor?: string
	maxWidth?: number
	noScrollComponent?: boolean
	left?: () => ReactNode
	/**
	 * Unlike `header`, this prop goes inside the scrollview.
	 */
	ListHeaderComponent?: () => ReactNode
}

function styleProp<StyleType>(base: StyleType, added?: StyleProp<StyleType>) {
	if (Array.isArray(added)) return [base, ...added]

	return [base, added]
}

const background = '#F7FAFC'

const Page = (props: Props) => {
	const {
		header,
		children,
		containerStyle,
		scrollViewProps = empty.object,
		scrollViewStyle,
		backgroundColor = background,
		maxWidth = 800,
		noScrollComponent = false,
		left,
		ListHeaderComponent,
	} = props

	const container = useMemo(
		() => styleProp<ViewStyle>({ maxWidth, width: '100%' }, containerStyle),
		[containerStyle, maxWidth]
	)
	const scroll = useMemo(
		() =>
			styleProp<ViewStyle>(
				{
					// width: '100%',
					padding: 16,
					// flex: 1,
					minHeight: '101%',
					backgroundColor,
					alignItems: 'center',
					paddingBottom: 100,
				},
				scrollViewStyle
			),
		[backgroundColor, scrollViewStyle]
	)
	const Scroll = noScrollComponent ? View : ScrollView
	return (
		<>
			{header?.()}
			<Scroll
				contentContainerStyle={scroll}
				showsVerticalScrollIndicator
				{...scrollViewProps}
			>
				<View style={[styles.wrapper]}>
					{left?.()}
					<View style={container}>
						{ListHeaderComponent?.()}
						{children}
					</View>
				</View>
			</Scroll>
		</>
	)
}

export default Page

const styles = StyleSheet.create({
	wrapper: {
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'center',
	},
	main: {
		// alignItems: 'center',
	},
})
