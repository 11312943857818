import React, { ReactNode } from 'react'
import FuegoProvider from './fuego'
import DoormanProvider from './doorman'

type Props = {
	children: ReactNode
}

const Providers = (props: Props) => {
	const { children } = props
	return (
		<>
			<FuegoProvider>
				<DoormanProvider>{children}</DoormanProvider>
			</FuegoProvider>
		</>
	)
}

export default Providers
