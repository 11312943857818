import { Timber } from '@timberio/browser'
import firebase from 'firebase/app'

export class Log {
	static logger = new Timber(
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2FwaS50aW1iZXIuaW8vIiwiZXhwIjpudWxsLCJpYXQiOjE1ODQ5MDQ2MTAsImlzcyI6Imh0dHBzOi8vYXBpLnRpbWJlci5pby9hcGlfa2V5cyIsInByb3ZpZGVyX2NsYWltcyI6eyJhcGlfa2V5X2lkIjo2NTk0LCJ1c2VyX2lkIjoiYXBpX2tleXw2NTk0In0sInN1YiI6ImFwaV9rZXl8NjU5NCJ9.sqDuRQg5IlWqdXHMEADv3PV99D8QYUHduHJT7q-D0DY',
		'35172'
	)
	static async log(...args: unknown[]) {
		return Log.logger.log(
			[firebase.auth().currentUser?.uid ?? '(user: none 🤷‍♂️)', args]
				.map(JSON.stringify)
				.join(', ')
		)
	}
	static error(...args: unknown[]) {
		return Log.logger.error(
			[
				'🚨 ERROR: ',
				firebase.auth().currentUser?.uid ?? '(user: none 🤷‍♂️)',
				args,
			]
				.map(JSON.stringify)
				.join(', ')
		)
	}
	static warn(...args: unknown[]) {
		return Log.logger.warn(
			['🚧 WARN: ', firebase.auth().currentUser?.uid ?? '(user: none 🤷‍♂️)', args]
				.map(JSON.stringify)
				.join(', ')
		)
	}
}
