import React, { useCallback } from 'react'
import { H1, H2 } from '@expo/html-elements'
import { ActivityIndicator, Text, StyleSheet, View } from 'react-native'
import ErrorItem from '../components/Error-Item'
import { Row } from '@nandorojo/bootstrap'
import RealTimeMonitor from '../components/Real-Time-Indicator'
import { useProjectCalls } from '../hooks/use-project-calls'
import { Switch, Button } from 'react-native-paper'
import { optimizeHeavyScreen } from 'react-navigation-heavy-screen'

type Props = {
	publicProjectId: string
}

const ProjectErrors = (props: Props) => {
	const { publicProjectId } = props
	const {
		data,
		loading,
		errorsOnly,
		setErrorsOnly,
		paginate,
	} = useProjectCalls({
		publicProjectId,
	})

	const list = useCallback(
		() => (
			<>
				{data.map(error => (
					<ErrorItem {...error} key={error.id} />
				))}
				{!!data.length && (
					<View style={styles.buttonContainer}>
						<Button mode="contained" loading={loading} onPress={paginate}>
							Fetch more
						</Button>
					</View>
				)}
			</>
		),
		[data, paginate, loading]
	)

	const loadingOrList = () => (loading ? <ActivityIndicator /> : list())
	const maybeEmpty = () => !loading && !data.length && <H2>No logs so far.</H2>

	return (
		<>
			<Row style={styles.row} alignItems="center">
				<Switch
					style={styles.switch}
					value={errorsOnly}
					onValueChange={setErrorsOnly}
				/>
				<Text>Show errors only</Text>
			</Row>
			{loadingOrList()}
			{maybeEmpty()}
		</>
	)
}

const Optimized = optimizeHeavyScreen(ProjectErrors)

const Errors = (props: Props) => {
	return (
		<>
			<Row justifyContent="space-between" alignItems="center">
				<H1>Logs</H1>
				<RealTimeMonitor />
			</Row>
			<Optimized {...props} />
		</>
	)
}

export default React.memo(Errors)

const styles = StyleSheet.create({
	row: {
		marginBottom: 16,
	},
	switch: {
		marginRight: 8,
	},
	buttonContainer: {
		padding: 16,
		alignItems: 'center',
	},
})
