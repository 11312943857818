import { useState, useCallback } from 'react'
import { Project } from '../api/project'

export const useDowngradeProject = ({
	publicProjectId,
}: {
	publicProjectId: string
}) => {
	const [mightDowngrade, setMightDowngrade] = useState(false)
	const [loading, setLoading] = useState(false)
	const [hasDowngraded, setHasDowngraded] = useState(false)

	const cancelSubscription = useCallback(async () => {
		setLoading(true)
		try {
			await new Project({ id: publicProjectId }).cancelSubscription()
			setLoading(false)
			setHasDowngraded(true)
		} catch (e) {
			setLoading(false)
			console.error('error cancelling subscription in useDowngrade', e)
		}
	}, [publicProjectId])

	return {
		mightDowngrade,
		setMightDowngrade,
		loading,
		cancelSubscription,
		hasDowngraded,
	}
}
