import { useNavigation } from 'react-navigation-hooks'
import { NavigationPaths } from '../paths/index'
import { useCallback, useEffect, useRef } from 'react'
import { ProjectsStackParams } from '../views/stacks/Projects-Stack'

export const useNavigateToProject = () => {
	const { navigate: nav, replace } = useNavigation()

	const navigate = useCallback(
		({ id, publicName, internalName }: ProjectsStackParams['Project']) =>
			nav({
				routeName: NavigationPaths.Project,
				params: { id, publicName, internalName },
			}),
		[nav]
	)
	const rep = useCallback(
		({ id, publicName, internalName }: ProjectsStackParams['Project']) =>
			replace({
				routeName: NavigationPaths.Project,
				params: { id, publicName, internalName },
			}),
		[replace]
	)

	return {
		navigate,
		replace: rep,
	}
}

export const useProjectNavigationParams = () => {
	const { getParam } = useNavigation()
	const internalName: string | undefined = getParam('internalName')
	const publicName: string | undefined = getParam('publicName')
	const id: string = getParam('id')
	const firebaseProjectId: string | undefined = getParam('firebaseProjectId')

	return {
		id,
		internalName,
		publicName,
		firebaseProjectId,
	}
}

export const useUpdateProjectTitleParam = ({ title }: { title?: string }) => {
	const { setParams } = useNavigation()
	const set = useRef(setParams)
	useEffect(() => {
		set.current = setParams
	})
	useEffect(() => {
		if (title) set.current({ title })
	}, [title])
}
