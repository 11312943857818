import { StyleSheet } from 'react-native'

export const ScreenStyle = StyleSheet.create({
	container: {
		padding: 16,
	},
	wrapper: {
		padding: 0,
	},
	buttonWrapper: {
		marginVertical: 16,
	},
	button: {
		paddingVertical: 4,
	},
	inputContainer: {
		marginVertical: 16,
	},
})
