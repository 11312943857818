import React, { useMemo, useCallback } from 'react'
import { AuthHistorySchema } from '../schema/authHistory'
import { View, Text, StyleSheet } from 'react-native'
import { Card, Colors, Divider, Chip } from 'react-native-paper'
import moment from 'moment'
import { colors } from '../style/colors'

type Props = AuthHistorySchema

const AuthHistoryItem = (props: Props) => {
	const {
		sent,
		queued,
		verified,
		createdAt,
		delivered,
		uid,
		phoneNumber,
	} = props

	const taskStatuses = useMemo(() => ({ queued, sent, delivered, verified }), [
		queued,
		sent,
		delivered,
		verified,
	])

	const statuses = useCallback(
		() => (
			<View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 8 }}>
				{Object.keys(taskStatuses).map((statusName, index) => {
					const timestamp: Date | null =
						taskStatuses[statusName as keyof typeof taskStatuses]
					const done = !!timestamp
					const title = statusName.split('At')[0]
					const message = timestamp
						? `${title} at ${moment(timestamp).calendar()}`
						: `Not ${title} yet.`
					const color = done
						? colors()[Object.keys(colors())[index]]
						: undefined

					return (
						<Chip
							key={statusName}
							selected={done}
							selectedColor={color?.color}
							style={{
								marginHorizontal: 8,
								marginBottom: 8,
								backgroundColor: done ? color?.shadowColor : undefined,
							}}
						>
							{message}
						</Chip>
					)
				})}
			</View>
		),
		[taskStatuses]
	)

	return (
		<View>
			<Divider />

			<Card.Title
				title={phoneNumber}
				subtitle={uid}
				right={() => (
					<>
						<Text style={styles.createdAt}>{moment(createdAt).calendar()}</Text>
						<Text>Created At</Text>
					</>
				)}
				rightStyle={styles.right}
			/>
			{statuses()}
		</View>
	)
}

export default React.memo(AuthHistoryItem)

const styles = StyleSheet.create({
	card: {
		// borderRadius: 0,
		margin: 8,
		marginBottom: 0,
	},
	price: {
		// color: Colors.green600,
		fontWeight: 'bold',
	},
	right: {
		marginRight: 8,
		alignItems: 'flex-end',
	},
	subtitle: {
		fontWeight: 'bold',
		color: Colors.black,
	},
	createdAt: {
		fontWeight: 'bold',
	},
	title: {
		marginLeft: 8,
	},
})
