import React from 'react'
import { createStackNavigator } from 'react-navigation-stack'
import CreateProject from '../../../views/Create-Project'
import ProjectView from '../../../views/Project/index'
import Projects from '../../../views/Projects/index'
import { NavigationPaths } from '../../paths/index'
import { DefaultTheme, Button, Colors } from 'react-native-paper'
import { fuego } from '../../../firebase/fuego'
import { Platform, View, Image, Text } from 'react-native'
import { A } from '@expo/html-elements'
import { Row } from '@nandorojo/bootstrap'
import { AppVersion } from '../../../../app-version'

export type ProjectsStackParams = {
	Project: { id: string; publicName?: string; internalName?: string }
}

export const ProjectsStack = createStackNavigator(
	{
		[NavigationPaths.CreateProject]: {
			screen: CreateProject,
			navigationOptions: { title: 'Create Project' },
		},
		[NavigationPaths.Project]: {
			screen: ProjectView,
			navigationOptions: ({ navigation }) => ({
				title: navigation.getParam('publicName') ?? '',
			}),
		},
		[NavigationPaths.Projects]: {
			screen: Projects,
			navigationOptions: {
				headerTitle: function headerTitle() {
					return (
						<Row alignItems="center">
							<Image
								source={require('../../../../assets/logo.png')}
								style={{ height: 75, width: 150 }}
								resizeMode="contain"
							/>
							<Text
								style={{
									marginLeft: 8,
									color: Colors.blueGrey300,
									fontSize: 12,
								}}
							>
								v{AppVersion}
							</Text>
						</Row>
					)
				},
			},
		},
	},
	{
		initialRouteName: NavigationPaths.Projects,
		defaultNavigationOptions: {
			cardStyle: { flex: 1, minHeight: '101%' },
			animationEnabled: Platform.OS !== 'web',
			gestureEnabled: Platform.OS !== 'web',
			// headerBackground: DefaultTheme.colors.primary,
			headerTintColor: 'black',
			headerStyle: {
				backgroundColor: 'white',
				borderBottomWidth: 0,
			},
			headerRight: function HearderRight() {
				return (
					<View style={{ flexDirection: 'row', paddingRight: 16 }}>
						<A href="https://docs.doorman.cool" target="_blank">
							<Button
								mode="contained"
								color="black"
								// labelStyle={{ color: 'black' }}
							>
								Docs
							</Button>
						</A>
						<Button
							labelStyle={{ color: 'black' }}
							onPress={() => fuego.auth().signOut()}
							icon=""
						>
							Sign out
						</Button>
					</View>
				)
			},
		},
		navigationOptions: {},
	}
)
