import React from 'react'
import { CallSchema } from '../schema/error'
import { Row } from '@nandorojo/bootstrap'
import { Strong, P, H3 } from '@expo/html-elements'
import moment from 'moment'
import CodeBlock from './Code-Block'
import { Card, Colors } from 'react-native-paper'
import { Divider } from 'react-native-elements'

type Props = CallSchema & {
	onPress?: (item: { publicProjectId?: string }) => void
}

const ErrorItem = (props: Props) => {
	const { createdAt, lastUpdated, requestBody, responseBody, onPress } = props
	const message = typeof responseBody === 'object' && responseBody.message

	const publicProjectId =
		typeof requestBody === 'object' ? requestBody.publicProjectId : undefined

	const maybeRenderCreatedAt = () =>
		!!createdAt && (
			<Strong style={{ color: message ? Colors.redA700 : Colors.greenA700 }}>
				{moment(createdAt).calendar()}
			</Strong>
		)
	const maybeRenderLastUpdated = () =>
		!!lastUpdated && <P>Updated {moment(lastUpdated).calendar()}</P>
	const maybeRenderMessage = () =>
		!!message && (
			<Row>
				<P>
					<Strong style={{ color: 'red' }}>Error message: </Strong> {message}
				</P>
			</Row>
		)

	const maybeRenderRequestBody = () =>
		!!requestBody && (
			<>
				<H3>Request body:</H3>
				<CodeBlock language="json">
					{JSON.stringify(requestBody, null, 4)}
				</CodeBlock>
			</>
		)
	const maybeRenderResponse = () =>
		!!responseBody && (
			<>
				<H3>Response: </H3>
				<CodeBlock language="json">
					{JSON.stringify(responseBody, null, 4)}
				</CodeBlock>
			</>
		)

	return (
		<Card
			onPress={() => onPress?.({ publicProjectId })}
			elevation={6}
			style={{ marginBottom: 16 }}
		>
			<Card.Content>
				<Row alignItems="center" justifyContent="space-between">
					{maybeRenderCreatedAt()}
					{maybeRenderLastUpdated()}
				</Row>
				<Divider style={{ marginVertical: 8 }} />
				{maybeRenderMessage()}
				{maybeRenderRequestBody()}
				{maybeRenderResponse()}
			</Card.Content>
		</Card>
	)
}

export default React.memo(ErrorItem)
