import useSWR from 'swr'
import { Project } from '../api/project'

export const useCheckIams = ({
	iamWorking,
	publicProjectId,
	loading,
}: {
	iamWorking: boolean
	publicProjectId: string
	loading: boolean
}) => {
	const swr = useSWR(
		() => (loading || iamWorking ? null : [publicProjectId, 'check-iams']),
		(id: string) => new Project({ id }).verifyIamsComplete(),
		{
			// refresh every 5 minutes
			// refreshInterval: iamWorking ? undefined : 5 * 60 * 1000,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		}
	)
	return swr
}
