import { YellowBox } from 'react-native'

YellowBox.ignoreWarnings([
	`Warning: componentWillMount has been renamed`,
	'Warning: componentWillReceiveProps has been renamed',
])

export * from './methods'
export * from './views'
export * from './hooks'
export * from './components'
export * from './context'
export * from './hoc'
