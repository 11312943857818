import React from 'react'
import { useProjects } from '../../hooks/use-projects'
import ProjectPreview from '../../components/Project-Preview'
import { View } from 'react-native'
import { ActivityIndicator, Button, Card } from 'react-native-paper'
import Page from '../../components/Page'
import { H1, P, H3, H4 } from '@expo/html-elements'
import { useNavigateToProject } from '../../navigation/hooks/use-navigate-to-project'
import { useNavigateToCreateProject } from '../../navigation/hooks/use-navigate-to-create-project'
import { useDoormanUser } from '../../packages/doorman_native/src'
import { useAnalyticsScreen } from '../../analytics/hooks/use-analytics-screen'

type Props = {}

const Projects = (props: Props) => {
	const {} = props
	const { data, loading } = useProjects()
	const { navigate } = useNavigateToProject()
	const { navigate: create } = useNavigateToCreateProject()
	const { displayName } = useDoormanUser()

	useAnalyticsScreen('Projects')

	const subtitle = data.length
		? '🌊 All of your projects live here.'
		: '🐙 Time to make your first app and get up and running!'

	return (
		<Page
		// header={() => (
		// 	<Appbar.Header>
		// 		<Appbar.Content title="Doorman" />
		// 	</Appbar.Header>
		// )}
		>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<View>
					<H1 style={{ margin: 0, paddingVertical: 8 }}>Your Projects</H1>
					<P>
						Create a different project for each Firebase app you want to add
						authentication to.
					</P>
				</View>
				<Button
					mode="contained"
					icon="plus"
					onPress={() => create({ firstProject: !loading && !data.length })}
				>
					New Project
				</Button>
			</View>
			<View>
				{loading && <ActivityIndicator />}
				{data.map((item, index) => {
					return (
						<ProjectPreview
							fullWidth={data.length === 1}
							key={item.id}
							{...item}
							index={index}
							onPress={navigate}
						/>
					)
				})}
				{!loading && !data.length && (
					<Card style={{ marginVertical: 16, paddingVertical: 16 }}>
						<Card.Content>
							<H4>
								Welcome to Doorman, {displayName}. Time to create your first
								project. 😇
							</H4>
						</Card.Content>
					</Card>
				)}
			</View>
		</Page>
	)
}

const keyExtractor = ({ id }: ReturnType<typeof useProjects>['data'][0]) => id

export default Projects
