import { useFuego } from '@nandorojo/fuego'
import { Project } from '../api/project'
import { ProjectSchema } from '../schema/project'
import { FireDoc } from '../types/document'
import { useMemo, useCallback, useRef, useEffect } from 'react'
import { empty } from '../utils/empty'
import { fuego } from '../firebase/fuego'
import { orderBy } from 'lodash'
import { useFocusEffect } from 'react-navigation-hooks'
import { useCollection } from '@nandorojo/swr-firestore'

export const useProjectsOld = () => {
  const query = useMemo<Parameters<typeof useFuego>[0]>(
    () => ({
      path: Project.collection,
      where: [
        'developers',
        'array-contains',
        fuego.auth().currentUser?.uid ?? '',
      ],
      // listen: true,
      // orderBy: ['createdAt', 'desc'],
    }),
    []
  )
  const { data, ...request } = useFuego<FireDoc<ProjectSchema<true>>>(query)

  // fetch again when you open the screen again
  const refetch = useRef<Function>()
  useFocusEffect(
    useCallback(() => {
      if (refetch.current) {
        console.log('will refetch...')
        refetch.current?.()
      }
    }, [])
  )
  useEffect(() => {
    refetch.current = request.refetch
  })
  const parsedData = useMemo(
    () =>
      data
        ? _.orderBy(
            (data as FireDoc<ProjectSchema<true>>[]).map(
              Project.parseForRender
            ),
            ['lastUpdated', 'createdAt'],
            'desc'
          )
        : empty.array,
    [data]
  )
  // const fetchAgain = useRef(refetch)
  // useEffect(() => {
  // 	fetchAgain.current = refetch
  // })
  // useFocusEffect(
  // 	useCallback(() => {
  // 		fetchAgain.current()
  // 	}, [])
  // )
  return { data: parsedData, ...request }
}

export const useProjects = () => {
  const { data, loading, revalidate, ...request } = useCollection<
    ProjectSchema<true>
  >(Project.collection, {
    where: ['developers', 'array-contains', fuego.auth().currentUser?.uid],
  })

  // fetch again when you open the screen again
  const refetch = useRef<typeof revalidate>()
  useFocusEffect(
    useCallback(() => {
      if (refetch.current) {
        console.log('will refetch...')
        refetch.current()
      }
    }, [])
  )
  useEffect(() => {
    refetch.current = revalidate
  })
  const parsedData = useMemo(
    () =>
      data
        ? orderBy(
            data.map(Project.parseForRender),
            ['lastUpdated', 'createdAt'],
            'desc'
          )
        : empty.array,
    [data]
  )
  // const fetchAgain = useRef(refetch)
  // useEffect(() => {
  // 	fetchAgain.current = refetch
  // })
  // useFocusEffect(
  // 	useCallback(() => {
  // 		fetchAgain.current()
  // 	}, [])
  // )
  return { data: parsedData, loading, revalidate, ...request }
}
