import React, { useState, useCallback } from 'react'
import { View, Text } from 'react-native'
import { Pre, Code, P, Strong, H1 } from '@expo/html-elements'
import { AppConstants } from '../../constants/app-constants'
import ProjectInstallation from './Installation'
import { useProjectNavigationParams } from '../../navigation/hooks/use-navigate-to-project'
import { useProject } from '../../hooks/use-project'
import Page from '../../components/Page'
import { ProjectStackFeatures } from '../../navigation/views/stacks/Project-Stack/project-stack-features'
import ProjectSideBar from '../../navigation/views/stacks/Project-Stack/Project-Side-Bar'
import AuthHistory from './AuthHistory'
import TestNumbers from '../Test-Numbers'
import { empty } from '../../utils/empty'
import Billing from '../Billing'
import { useBilling } from '../../hooks/use-billing'
import BillingNotice from '../../components/Billing-Notice'
import { Row } from '@nandorojo/bootstrap'
import { Colors, Divider, ActivityIndicator } from 'react-native-paper'
import PremiumChip from '../../components/Premium-Chip'
import ProjectErrors from '../Project-Errors'
import { useCheckIams } from '../../hooks/use-check-iams'

type Props = {
  // feature: ValueOf<typeof ProjectStackFeatures>
  // featureId: keyof ProjectStackScreens
}

export default function ProjectView() {
  // const { feature, featureId } = props
  const { id, ...params } = useProjectNavigationParams()
  const { data: billing, loading: loadingBilling } = useBilling({
    publicProjectId: id,
  })

  let { publicName, internalName, firebaseProjectId } = params
  const { data, loading } = useProject({ id })
  publicName = data?.publicName ?? publicName
  internalName = data?.internalName ?? internalName
  firebaseProjectId = data?.firebaseProjectId ?? firebaseProjectId ?? ''
  const isWorking = !!data?.lastDeployment?.isWorking
  const iamWorking = !!data?.lastDeployment?.iamWorking
  const appleCode = data?.appleCode
  const appleTestNumber = data?.appleTestNumber

  const isPaidProject = !!billing?.billTo

  const {
    revalidate: checkForIams,
    isValidating: checkForIamsLoading,
  } = useCheckIams({
    iamWorking,
    loading,
    publicProjectId: id,
  })

  // useUpdateProjectTitleParam({ title: data?.internalName ?? data?.publicName })

  const [activeScreen, setActiveScreen] = useState<
    keyof typeof ProjectStackFeatures
  >(isWorking ? 'Debugger' : 'Install')
  // >('Billing')

  const navigateToBilling = useCallback(() => setActiveScreen('Billing'), [])
  const navigateToTestNumbers = useCallback(
    () => setActiveScreen('Test Numbers'),
    []
  )

  // const [active, setActive] = useState('install')
  const renderPage = useCallback(() => {
    if (activeScreen === 'Install')
      return (
        <ProjectInstallation
          publicName={publicName ?? 'Loading...'}
          id={id}
          firebaseProjectId={firebaseProjectId ?? 'Loading firebase...'}
          loading={loading}
          publicProjectId={id}
          isPaidProject={isPaidProject}
          navigateToTestNumbers={navigateToTestNumbers}
          navigateToBilling={navigateToBilling}
          lastDeployment={data?.lastDeployment}
          // isDeployedSuccessfully={isWorking && iamWorking}
          isWorking={isWorking}
          iamWorking={iamWorking}
          checkForIams={checkForIams}
          checkForIamsLoading={checkForIamsLoading}
        />
      )
    if (activeScreen === 'Debugger') return <AuthHistory publicProjectId={id} />
    if (activeScreen === 'Test Numbers') {
      return (
        <TestNumbers
          phoneNumber={data?.defaultPhoneNumber}
          testNumbers={data?.testNumbers ?? empty.array}
          loading={loading}
          appleCode={data?.appleCode}
          appleTestNumber={data?.appleTestNumber}
        />
      )
    }
    if (activeScreen === 'Billing') {
      if (!billing) return <ActivityIndicator />

      return (
        <Billing
          {...billing}
          publicProjectId={id}
          isPaidProject={isPaidProject}
          loading={loadingBilling}
          navigateToTestNumbers={navigateToTestNumbers}
        />
      )
    }
    if (activeScreen === 'Errors') {
      return <ProjectErrors publicProjectId={id} />
    }
    return null
  }, [
    activeScreen,
    publicName,
    id,
    firebaseProjectId,
    loading,
    isPaidProject,
    navigateToTestNumbers,
    navigateToBilling,
    data,
    isWorking,
    iamWorking,
    checkForIams,
    checkForIamsLoading,
    billing,
    loadingBilling,
  ])

  const left = useCallback(
    () => (
      <ProjectSideBar
        active={activeScreen}
        onSelectFeature={setActiveScreen}
        isWorking={!!data?.lastDeployment?.isWorking}
        loading={loading}
        iamWorking={iamWorking}
      />
    ),
    [activeScreen, data, iamWorking, loading]
  )

  const ListHeaderComponent = useCallback(
    () => (
      <>
        <Row alignItems="flex-end" justifyContent="space-between">
          <View>
            <Row alignItems="center">
              <H1 style={{ margin: 0, paddingVertical: 8 }}>
                {internalName || publicName}
              </H1>
              <PremiumChip
                isPaidProject={isPaidProject}
                loading={loadingBilling}
              />
            </Row>
            <P>
              {!!internalName ? `${publicName} | ` : ''}
              <Code>
                <Strong>{id}</Strong>
              </Code>
            </P>
          </View>
          <View style={{ alignItems: 'flex-end' }}>
            <P style={{ opacity: firebaseProjectId ? 1 : 0 }}>
              Firebase ID: {firebaseProjectId}
            </P>
          </View>
        </Row>
        <Divider style={{ marginBottom: 16 }} />
      </>
    ),
    [
      firebaseProjectId,
      id,
      internalName,
      isPaidProject,
      loadingBilling,
      publicName,
    ]
  )

  return (
    <Page left={left} maxWidth={600} ListHeaderComponent={ListHeaderComponent}>
      {renderPage()}
    </Page>
  )
}
