import React from 'react'
import { View, Text } from 'react-native'
import { RadioButton } from 'react-native-paper'

type Props = {
	options: { id: string; value: string }[]
	onSelect: (id: string) => void
	selectedId: string
}

const RadioPickerSet = (props: Props) => {
	const { options, onSelect, selectedId } = props
	return (
		<RadioButton.Group onValueChange={id => onSelect(id)} value={selectedId}>
			{options.map(({ id, value }) => (
				<View
					key={id}
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						marginVertical: 8,
					}}
				>
					<RadioButton
						// status={id === selectedId ? 'checked' : 'unchecked'}
						// onPress={() => onSelect({ id, value })}
						value={id}
					/>
					<Text style={{ marginLeft: 16 }}>{value}</Text>
				</View>
			))}
		</RadioButton.Group>
	)
}

export default RadioPickerSet
