import React, { useState } from 'react'
import { P, H1, H3, Strong } from '@expo/html-elements'
import Stripe from '../../components/Stripe'
import CardSection from '../../components/Card-Section'
import BillingMonths from '../../components/Billing-Months'
import { useBillingMonths } from '../../hooks/use-billing-months'
import { Colors, Button } from 'react-native-paper'
import { Row } from '@nandorojo/bootstrap'
import BillingOverview from '../../components/Billing-Overview'
import { BillingProps } from './types'
import { View, Alert } from 'react-native'
import { optimizeHeavyScreen } from 'react-navigation-heavy-screen'
import { useDowngradeProject } from '../../hooks/use-downgrade'
import BillingNotice from '../../components/Billing-Notice'

const Billing = (props: BillingProps) => {
	const {
		publicProjectId,
		isPaidProject,
		loading: loadingBilling,
		navigateToTestNumbers,
		...billing
	} = props

	const {
		mightDowngrade,
		setMightDowngrade,
		loading: downgradeLoading,
		hasDowngraded,
		cancelSubscription,
	} = useDowngradeProject({ publicProjectId })

	const { data, loading } = useBillingMonths({ publicProjectId })
	return (
		<>
			<Row alignItems="center" justifyContent="space-between">
				<H1>Billing</H1>
			</Row>

			<BillingNotice
				// onNavigateToBilling={navigateToBilling}
				loading={loadingBilling}
				isPaidAccount={isPaidProject}
				onNavigateToTestNumbers={navigateToTestNumbers}
			/>
			<CardSection
				icon="md-card"
				title={isPaidProject ? 'Change Payment Method' : 'Add Payment Method'}
				handleState={!isPaidProject ? 'open' : 'closed'}
				color={Colors.deepPurpleA400}
			>
				{!isPaidProject && !loadingBilling && (
					<View>
						<H3 style={{ marginBottom: 8 }}>
							Upgrade to premium. Cancel any time.
						</H3>
						<P>$10/month + $0.05 per SMS ($0.10 per SMS outside the US)</P>
					</View>
				)}
				<Stripe
					publicProjectId={publicProjectId}
					isPaidProject={isPaidProject}
				/>
			</CardSection>

			{!!isPaidProject && (
				<>
					<BillingOverview {...billing} loading={loadingBilling} />
					<CardSection
						icon="md-calendar"
						handleState="open"
						title="History"
						color={Colors.pink400}
					>
						<BillingMonths billingMonths={data} loading={loading} />
					</CardSection>
				</>
			)}
			{!!isPaidProject && (
				<>
					{
						// 	icon="md-create"
						// 	handleState="closed"
						// 	title="Edit Subscription"
						// 	color={Colors.blue600}
						// >
					}
					{hasDowngraded ? (
						<>
							<H3>
								Your project has successfully downgraded to a free test account.
							</H3>
						</>
					) : !mightDowngrade ? (
						<>
							<Button
								onPress={() => setMightDowngrade(true)}
								color={Colors.redA700}
							>
								Downgrade Premium Subscription
							</Button>
						</>
					) : (
						<View>
							<H3 style={{ marginBottom: 8 }}>
								Are you sure about downgrading?
							</H3>
							<P>
								If you downgrade to a test account,{' '}
								<Strong>SMS authentication will be disabled.</Strong>
							</P>
							<P>{`It's your call, of course. Just want to make sure you know that your app won't work if you do.`}</P>
							<Row justifyContent="space-between">
								<Button
									onPress={() => setMightDowngrade(false)}
									color={Colors.deepPurpleA700}
									mode="contained"
								>
									Go Back
								</Button>
								<Button
									onPress={cancelSubscription}
									color={Colors.redA700}
									mode="contained"
									loading={downgradeLoading}
								>
									I understand. Downgrade anyway.
								</Button>
							</Row>
						</View>
					)}
				</>
			)}
		</>
	)
}

export default optimizeHeavyScreen(Billing)
