import React, { useEffect } from 'react'
import CodeBlock from '../../components/Code-Block'
import { AppConstants } from '../../constants/app-constants'
import { P, Strong, H3, EM } from '@expo/html-elements'
import { colors } from '../../style/colors'
import { fakeResize } from '../../utils/fake-resize'

type Props = {
	apiSecret: string
	firebaseProjectId: string
}

const CLI = (props: Props) => {
	const { firebaseProjectId, apiSecret } = props
	useEffect(() => {
		fakeResize()
	}, [])
	return (
		<>
			<P
				style={{
					padding: 16,
					backgroundColor: colors().green.shadowColor,
					borderRadius: 5,
				}}
			>
				✅ Your API secret is: <Strong>{apiSecret}</Strong>
			</P>
			<P>
				<EM>
					Any previous API secret and deployment is now invalid. You must deploy
					with this key for your app to work.
				</EM>
			</P>
			<H3>2.2 Deploy your app</H3>

			<P>{`You will now use this secret key to deploy your Doorman app. Copy the command below and run it in the root of your React Native app.`}</P>
			{/* <P>{`Change directories to the root of your React Native app.`}</P> */}
			{/* <CodeBlock>{`cd my-app-name`}</CodeBlock>
		<P>
			Next, run our command line <Strong>using your public keys.</Strong>{' '}
			Click to copy the command below.
			{'\n'}
		</P> */}
			<CodeBlock>
				{`npx ${AppConstants.cliName} --firebaseProjectId=${firebaseProjectId} --apiSecret=${apiSecret}`}
			</CodeBlock>
			{/* <P>Syntax is as follows:</P>
		<Pre style={{ backgroundColor: 'lightGray', color: 'green' }}>
			{`npx ${AppConstants.cliName} <FIREBASE-PROJECT-ID> <DOORMAN-PUBLIC-APP-ID>`}
		</Pre> */}
			<P>
				Wait for your browser to pop up,{' '}
				<Strong>
					authenticate the correct Google account associated with your Firebase
					project
				</Strong>{' '}
				when it does, and monitor the deployment below.
			</P>
			{/*<H3>Once you run the command...</H3>
			<P>Sit tight, this can take a few minutes.</P>
			<P>
				About a minute after running the command, your browser should open
				automatically, with a window promting you to log in to Firebase.{' '}
				<Strong>
					Make sure you sign into the correct Google account connected to your
					Firebase app,
				</Strong>{' '}
				and authenticate.{' '}
			</P>
			<P>{`You'll know authentication is done when it sends you back to the terminal. This step can take a few minutes after you've authenticated.`}</P>*/}
		</>
	)
}

export default CLI
