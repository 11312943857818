import { useFuego } from '@nandorojo/fuego'
import { AuthHistory } from '../api/auth-history'
import { useMemo } from 'react'
import { empty } from '../utils/empty'
import { AuthHistorySchema } from '../schema/authHistory'
import { FireDoc } from '../types/document'

export const useAuthHistory = ({
	publicProjectId,
}: {
	publicProjectId: string
}) => {
	const { data: unparsed, ...rest } = useFuego<
		FireDoc<AuthHistorySchema<true>>
	>({
		path: new AuthHistory({ id: publicProjectId }).path,
		listen: true,
		orderBy: ['createdAt', 'desc'],
		limit: 10,
	})

	const data = useMemo<FireDoc<AuthHistorySchema>[]>(
		() =>
			(unparsed as FireDoc<AuthHistorySchema<true>>[])?.map(
				AuthHistory.parseForRender
			) ?? empty.array,
		[unparsed]
	)

	return {
		...rest,
		data,
	}
}
