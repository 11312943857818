import React from 'react'
import { createAppContainer } from 'react-navigation'
import { ProjectsStack } from './views/stacks/Projects-Stack'

type Props = {
	hasOnboarded?: boolean
}

const AuthedApp = createAppContainer(ProjectsStack)

const Navigator = (props: Props) => {
	const { hasOnboarded = true } = props
	return <AuthedApp />
	// return (
	// 	<>
	// 		<AuthedApp />
	// 	</>
	// )
}

export default Navigator
