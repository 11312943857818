import React from 'react'
import Page from '../components/Page'
import { TextInput, Button } from 'react-native-paper'
import { useCreateProject } from '../hooks/use-create-project'
import { H1, H3, P, H5, Pre, Strong, A } from '@expo/html-elements'
import { StyleSheet, View } from 'react-native'
import CardSection from '../components/Card-Section'
import { colors } from '../style/colors'
import { useNavigateToProject } from '../navigation/hooks/use-navigate-to-project'
import { useCreateProjectParams } from '../navigation/hooks/use-navigate-to-create-project'
import SmsPreview from '../components/Sms-Preview'
import { useAnalyticsScreen } from '../analytics/hooks/use-analytics-screen'

type Props = {}

const CreateProject = () => {
	const { firstProject } = useCreateProjectParams()
	const { replace } = useNavigateToProject()

	useAnalyticsScreen('Create Project')

	const {
		firebaseProjectId,
		internalName,
		publicName,
		updateFirebaseProjectId,
		updatePublicName,
		create,
		loading,
	} = useCreateProject({
		onCreateSuccess: ({ success, publicProjectId, ...names }) => {
			if (success) replace({ ...names, id: publicProjectId })
			console.log('project created', {
				success,
				publicProjectId,
				publicName,
				internalName,
			})
		},
	})

	const validName = publicName.trim().length > 2

	const message = firstProject
		? 'Create your first Doorman project 🥳'
		: 'Create a new project 🏄🏽‍♂️'
	// return null

	return (
		<Page
		// header={() => (
		// 	<Appbar.Header style={{ zIndex: 1 }}>
		// 		<Appbar.BackAction onPress={() => fuego.auth().signOut()} />
		// 		<Appbar.Content title="New Doorman Project" />
		// 	</Appbar.Header>
		// )}
		>
			<H1 style={styles.h1}>{message}</H1>
			<P style={styles.p}>
				{`Let's get phone authentication set up in no time! (You should have a Doorman project for each React Native app you make.)`}
			</P>
			<CardSection
				title="1. Name your project"
				hideArrow
				color={colors().green.color}
				shadowColor={colors().green.shadowColor}
				icon="ios-planet"
				open
			>
				<View style={styles.card}>
					<View
						style={{
							marginBottom: 16,
							flexDirection: 'row',
							alignItems: 'flex-end',
						}}
					>
						<View style={{ flex: 1 }}>
							<H3>What is your app called?</H3>
							<P style={styles.p}>
								{
									//`This is the name of your React Native app, used in text messages to your users.`
									"This is the name your users will see. Don't have a name yet? Don't worry, you can edit it later."
								}
							</P>
							<TextInput
								onChangeText={updatePublicName}
								value={publicName}
								mode="outlined"
								label="Public App Name"
								style={{
									backgroundColor: colors().lightGray.color,
									marginBottom: 8,
								}}
								underlineColor={colors().blue.color}
								selectionColor={colors().blue.color}
								maxLength={40}
							/>
						</View>

						<View style={{ paddingLeft: 16, paddingBottom: 8 }}>
							<H5 style={styles.h2}>What your users will see:</H5>
							<SmsPreview name={publicName} />
						</View>
					</View>
					{/*<Divider />
					<View>
						<H3>(Optional) Project Nickname</H3>
						<P style={styles.p}>
							An internal nickname for this project only seen by you on the
							Doorman dashboard. You can leave it blank if you want.
						</P>
						<TextInput
							onChangeText={updateInternalName}
							value={internalName}
							mode="outlined"
							label="(Optional) Nickname"
							style={{
								backgroundColor: colors().lightGray.color,
								marginBottom: 8,
							}}
							underlineColor={colors().blue.color}
							selectionColor={colors().blue.color}
							maxLength={20}
						/>
						</View>*/}
				</View>
			</CardSection>
			<CardSection
				title="2. Integrate with Firebase"
				hideArrow
				color={colors().red.color}
				shadowColor={colors().red.shadowColor}
				icon="ios-flame"
				open={validName}
			>
				<View style={styles.card}>
					<H3>Enter Firebase Project ID</H3>
					<P style={styles.p}>
						Paste the{' '}
						<Strong>
							<Pre>projectId</Pre>
						</Strong>{' '}
						from your Firebase <Pre>config</Pre> below.
					</P>
					<P>
						<A
							href="https://docs.doorman.cool/introduction/getting-started/one-line-deploy#find-my-firebase-project-id"
							target="_blank"
							style={{ color: 'green' }}
						>
							How do I find my Firebase project ID?
						</A>
					</P>
					<TextInput
						onChangeText={updateFirebaseProjectId}
						value={firebaseProjectId}
						mode="outlined"
						label="Firebase Project ID"
						style={{
							backgroundColor: colors().lightGray.color,
							marginBottom: 8,
						}}
						underlineColor={colors().red.color}
						selectionColor={colors().red.color}
						// maxLength={20}
					/>
				</View>
			</CardSection>
			{!!firebaseProjectId.length && (
				<View
					style={{
						margin: 8,
						flexDirection: 'row',
						justifyContent: 'flex-end',
					}}
				>
					<Button
						mode="contained"
						loading={loading}
						onPress={loading ? undefined : create}
					>
						Create Project
					</Button>
				</View>
			)}
		</Page>
	)
}

export default CreateProject

const styles = StyleSheet.create({
	h1: {
		// marginBottom: 8,
	},
	h2: {
		marginTop: 0,
		marginBottom: 8,
	},
	card: {
		paddingHorizontal: 8,
		// flexDirection: 'row',

		// paddingVertical: 24,
	},
	text: {
		// color: DefaultTheme.colors.background,
	},
	p: {
		marginTop: 0,
		lineHeight: 20,
	},
})
