import React, {
	useState,
	ReactNode,
	useCallback,
	useMemo,
	ComponentPropsWithoutRef,
} from 'react'
import { AuthGate, DoormanProvider } from '../packages/doorman_native/src'
import CreateDeveloper from '../views/Create-Developer'
import { AuthFlow } from '../packages/doorman_native/src/views/Controlled/index'
import { analytics } from '../analytics'
import { Platform } from 'react-native'

// if (Platform.OS === 'web') {
// 	// temporary for web...
// 	require('react-phone-number-input/style.css')
// 	require('./style.css')
// }

type Props = {
	children: ReactNode
}

const Doorman = (props: Props) => {
	const { children } = props
	const [userReady, setUserReady] = useState(false)
	const onSmsSuccessfullySent = useCallback(({ phoneNumber }) => {
		analytics.trackWithProperites({
			event: 'Sent SMS',
			properties: {
				phoneNumber,
			},
		})
	}, [])
	const onCodeVerified = useCallback(() => {
		analytics.trackWithProperites({
			event: 'Verified SMS Code',
		})
	}, [])
	const phoneScreenProps = useMemo<
		ComponentPropsWithoutRef<typeof AuthFlow>['phoneScreenProps']
	>(
		() => ({
			headerText: 'Welcome to Doorman',
			headerTitleStyle: { marginTop: 16 },
			title: 'Enter your phone number to continue',
		}),
		[]
	)
	return (
		<DoormanProvider publicProjectId="PpHKIDrPhtVNfb8I3Ec8">
			<AuthGate>
				{({ user, loading }) => {
					if (loading) return <></>
					if (user && !user.displayName && !userReady)
						return (
							<CreateDeveloper onDeveloperCreated={() => setUserReady(true)} />
						)

					if (user) return <>{children}</>

					return (
						<AuthFlow
							onSmsSuccessfullySent={onSmsSuccessfullySent}
							onCodeVerified={onCodeVerified}
							phoneScreenProps={phoneScreenProps}
						/>
					)
				}}
			</AuthGate>
		</DoormanProvider>
	)
}

export default Doorman
