import React from 'react'
import { Bubble } from 'chat'
import { DefaultTheme } from 'react-native-paper'
import { SmsPreviewProps } from './types'

const SmsPreview = (props: SmsPreviewProps) => {
	const { name } = props
	return (
		<>
			<Bubble
				text={`Your ${name || 'UberEats'} verification code is: 930294`}
				backgroundColor={[
					DefaultTheme.colors.primary,
					DefaultTheme.colors.primary,
				]}
				textColor="white"
				style={{ maxWidth: 250 }}
			/>
		</>
	)
}

export default SmsPreview
