import { useFocusEffect } from 'react-navigation-hooks'
import { useCallback, useEffect } from 'react'
import { analytics } from '../index'
import { Screens, ProjectSubScreens } from '../types'
import { ProjectStackFeatures } from '../../navigation/views/stacks/Project-Stack/project-stack-features'

// export const useAnalyticsScreen = (
// 	info: Parameters<typeof analytics.trackScreen>[0]
// ) => {
// 	useFocusEffect(
// 		useCallback(() => {
// 			analytics.trackScreen(info)
// 		}, [info])
// 	)
// }

export const useAnalyticsProject = (
	subScreen: keyof typeof ProjectStackFeatures
) => {
	useEffect(() => {
		analytics.trackScreen({ screen: 'Project', subScreen })
	}, [subScreen])
}

export const useAnalyticsScreen = (screen: Screens) => {
	useEffect(() => {
		analytics.trackScreen({ screen })
	}, [screen])
}
