import React, { useCallback } from 'react'
import { colors } from '../../style/colors'
import { View, StyleSheet, Text } from 'react-native'
import { A, P, H4, Strong } from '@expo/html-elements'
import { ProjectSchema } from '../../schema/project'
import { Row } from '@nandorojo/bootstrap'
import Timeago from 'react-native-timeago'
import Hyperlink from 'react-native-hyperlink'

type Props = {
  loading: boolean
} & ProjectSchema['lastDeployment']

const ConfirmDeployment = (props: Props) => {
  const {
    loading,
    lastUpdated,
    isWorking = false,
    status = 0,
    message,
    totalSteps,
    error = false,
    warning = false,
    iamWorking,
  } = props

  const fullyWorking = iamWorking && isWorking

  let deploymentDescription = '✅ Deployed Successfully'
  if (!fullyWorking || warning || error) {
    deploymentDescription = message ?? `Deployment hasn't started yet.`
  }

  let color = fullyWorking ? colors().green.color : colors().blue.color

  let backgroundColor = fullyWorking
    ? colors().green.shadowColor
    : colors().blue.shadowColor
  if (warning) {
    backgroundColor = colors().yellow.shadowColor
    color = colors().yellow.color
  }
  if (error) {
    backgroundColor = colors().red.shadowColor
    color = colors().red.color
  }

  const timestamp = () => {
    if (lastUpdated) {
      if (isWorking) {
        return <Timeago time={lastUpdated} />
      }
      return (
        <Text>
          Updated <Timeago time={lastUpdated} />
        </Text>
      )
    }
    return <Text>Waiting for updates...</Text>
  }
  // let lastDeployment = lastUpdated
  // 	? `Updated at ${moment(lastUpdated).calendar()}`
  // 	: 'This might take a few minutes. Waiting for updates...'

  // if (lastUpdated && !isWorking) {
  // 	lastDeployment = `Updated ${moment(lastUpdated).calendar()}.`
  // }

  const steps = useCallback(() => {
    if (!totalSteps) {
      return null
    }

    return (
      <>
        <P>
          <Strong>Progress:</Strong>
        </P>
        <Row>
          {new Array(totalSteps).fill(' ').map((_, stepIndex) => {
            const step = stepIndex + 1
            const isError = error && step === status
            const isWarning = warning && step === status
            const isDone = step <= status
            let emoji = isDone ? '✅' : '⬜️'
            if (isWarning) {
              emoji = '🚧'
            }
            if (isError) {
              emoji = '🚨'
            }
            return (
              <View
                key={stepIndex.toString()}
                style={{ alignItems: 'center', marginHorizontal: 4 }}
              >
                <Text>{emoji}</Text>
                <Text>{`${step}`}</Text>
              </View>
            )
          })}
        </Row>
      </>
    )
  }, [totalSteps, error, status, warning])

  return !loading ? (
    <>
      <View style={[styles.wrapper, { backgroundColor }]}>
        <View style={{ flex: 1 }}>
          <H4 style={{ color, fontWeight: 'bold', marginTop: 0 }}>
            Status: {deploymentDescription}
          </H4>
        </View>
        <Row justifyContent="space-between">
          {steps()}
          <Text>{timestamp()}</Text>
        </Row>
      </View>
      {!!fullyWorking && !warning && !error && (
        <>
          <P>
            🔥🎉 {"That's"} it for the backend. If you ran into any problems or
            want help, check out our{' '}
            <A
              href="https://docs.doorman.cool"
              target="_blank"
              style={{ color: 'green', fontWeight: 'bold' }}
            >
              installation docs
            </A>
            , or chat with us in the bottom right corner.
          </P>
          <P>Continue to the next step.</P>
        </>
      )}
    </>
  ) : null
}

export default ConfirmDeployment

const styles = StyleSheet.create({
  wrapper: {
    padding: 16,
    borderRadius: 5,
  },
})
