import React, { useState } from 'react'
import Page from '../components/Page'
import { TextInput, Button, Appbar } from 'react-native-paper'
import { useCreateDeveloper } from '../hooks/use-create-developer'
import { H1, P } from '@expo/html-elements'
import RadioPickerSet from '../components/Radio-Picker-Set'
import { StyleSheet, View } from 'react-native'
import CardSection from '../components/Card-Section'
import { colors } from '../style/colors'
import { useAnalyticsScreen } from '../analytics/hooks/use-analytics-screen'

const ReferredBy = [
	'Twitter',
	'Reddit',
	'A friend',
	'Google',
	'I forget 😇',
	`Expo's SDK 37 announcement`,
	`Expo Canny`,
].map(value => ({ id: value, value }))

const UseCase = [
	'For a client',
	'For my own app',
	'For both a client and my own app',
	'Just checking Doorman out',
].map(value => ({ id: value, value }))

type Props = {
	onDeveloperCreated?: () => void
}

const CreateDeveloper = (props: Props) => {
	const { onDeveloperCreated } = props
	const {
		displayName,
		updateDisplayName,
		updateReferredBy,
		referredBy,
		updateUseCase,
		useCase,
		create,
		readyToCreate,
		loading,
	} = useCreateDeveloper({
		onCreateSuccess: () => {
			onDeveloperCreated?.()
			// navigate({ firstProject: true })
		},
	})

	const [nameReady, setNameReady] = useState(false)
	const validName = displayName.length > 5 && displayName.trim().includes(' ')
	useAnalyticsScreen('Create Developer')

	return (
		<>
			<Page
				header={() => (
					<Appbar.Header style={{ zIndex: 1 }}>
						<Appbar.Content title="Doorman" />
					</Appbar.Header>
				)}
				maxWidth={600}
				// backgroundColor={DefaultTheme.colors.primary}
			>
				<H1 style={styles.h1}>Welcome to Doorman</H1>
				<P
					style={styles.p}
				>{`You're one step closer to having phone auth in your app. 🥳`}</P>
				<CardSection
					title="What's your name?"
					subtitle={`First and last, please. Don't worry about company or app name for now.`}
					hideArrow
					color={colors().blue.color}
					shadowColor={colors().blue.shadowColor}
					icon="ios-person"
					open
				>
					<View style={styles.card}>
						<TextInput
							onChangeText={updateDisplayName}
							value={displayName}
							mode="outlined"
							// placeholder="Full name"
							label="Full name"
							style={{
								backgroundColor: colors().lightGray.color,
								marginBottom: 8,
							}}
							underlineColor={colors().blue.color}
							selectionColor={colors().blue.color}
							onSubmitEditing={() => setNameReady(true)}
							maxLength={20}
						/>
						<View
							style={{
								alignItems: 'flex-end',
								display: !validName ? 'none' : 'flex',
							}}
						>
							<Button
								mode="contained"
								onPress={() => validName && setNameReady(true)}
							>
								Continue
							</Button>
						</View>
					</View>
				</CardSection>

				<CardSection
					title={`What are you planning on using Doorman for?`}
					hideArrow
					color={colors().red.color}
					shadowColor={colors().red.shadowColor}
					icon="ios-code"
					open={validName && nameReady}
				>
					<View style={styles.card}>
						<RadioPickerSet
							options={UseCase}
							selectedId={useCase}
							onSelect={updateUseCase}
						/>
					</View>
				</CardSection>
				<CardSection
					title={`How'd you hear about us`}
					hideArrow
					open={
						!!(displayName.length > 5 && displayName.includes(' ') && useCase)
					}
					color={colors().green.color}
					shadowColor={colors().green.shadowColor}
					icon="ios-megaphone"
				>
					<View style={styles.card}>
						<RadioPickerSet
							options={ReferredBy}
							selectedId={referredBy}
							onSelect={updateReferredBy}
						/>
					</View>
				</CardSection>
				{readyToCreate && (
					<Button
						mode="contained"
						onPress={!loading ? create : undefined}
						loading={loading}
						style={{ margin: 16 }}
					>
						{loading ? 'One sec...' : 'Complete'}
					</Button>
				)}
			</Page>
		</>
	)
}

export default CreateDeveloper

const styles = StyleSheet.create({
	h1: {
		// marginBottom: 8,
		marginBottom: 0,
	},
	h2: {
		marginTop: 0,
		marginBottom: 8,
	},
	card: {
		paddingHorizontal: 8,
		// paddingVertical: 24,
	},
	text: {
		// color: DefaultTheme.colors.background,
	},
	p: {
		fontSize: 20,
	},
})
