import React, { useCallback } from 'react'
import { View } from 'react-native'
import { P, H3, Strong } from '@expo/html-elements'
import { Button } from 'react-native-paper'
import { colors } from '../../style/colors'
import { Row } from '@nandorojo/bootstrap'

type Props = {
	create: () => void
	apiSecret?: string
	loading: boolean
	alreadyDeployed: boolean
	createAndOverrideKey: () => void
	iamWorking: boolean
	checkForIams: () => void
	checkForIamsLoading: boolean
}

// this became a mess. oof. fix it someday lol
const CreateApiSecret = (props: Props) => {
	const {
		create,
		loading,
		apiSecret,
		alreadyDeployed,
		createAndOverrideKey,
		iamWorking,
		checkForIams,
		checkForIamsLoading,
	} = props

	console.log('[api-secret]', { iamWorking })

	const renderWarning = useCallback(() => {
		if (alreadyDeployed && !iamWorking) {
			return (
				<>
					<View
						style={{
							padding: 16,
							backgroundColor: colors().yellow.shadowColor,
							borderRadius: 5,
							marginBottom: 16,
						}}
					>
						<H3 style={{ marginTop: 0, marginBottom: 16 }}>
							⚠️ IAMs not set up
						</H3>
						<P style={{ marginBottom: 0 }}>
							Your app has successfully deployed.
						</P>
						<P>
							However, it seems like your IAM permissions from step 1 have not
							been completed.
						</P>
						<P>
							<Strong>
								Once you have configured your IAM permissions, click Refresh IAM
								Permissions below.
							</Strong>
						</P>
						<Row>
							<Button
								mode={'contained'}
								onPress={checkForIams}
								disabled={checkForIamsLoading}
								loading={checkForIamsLoading}
							>
								{checkForIamsLoading
									? 'Refreshing IAM Permissions'
									: 'Refresh IAM Permissions'}
							</Button>
						</Row>
					</View>
				</>
			)
		}

		return (
			<>
				<View
					style={{
						padding: 16,
						backgroundColor: colors().green.shadowColor,
						borderRadius: 5,
						marginBottom: 16,
					}}
				>
					<H3 style={{ marginTop: 0, marginBottom: 16 }}>
						✅ Already successfully deployed
					</H3>
					<P style={{ marginBottom: 0 }}>
						<Strong>You do not need to create a Secret Key.</Strong> Move on to
						step 3.
					</P>
				</View>

				<P>
					🚨 If you create a new secret key,{' '}
					<Strong>your current deployment will no longer work.</Strong> We
					recommend continuing on to step 3, and not creating a new key.
				</P>
				<Row>
					<Button
						loading={loading}
						disabled={loading}
						onPress={createAndOverrideKey}
						color={'red'}
						mode="outlined"
					>
						{loading ? 'Creating...' : `Create New Secret Key Anyway`}
					</Button>
				</Row>
			</>
		)
	}, [
		alreadyDeployed,
		checkForIams,
		checkForIamsLoading,
		createAndOverrideKey,
		iamWorking,
		loading,
	])
	const renderCreate = useCallback(() => {
		return (
			<View style={{ flexDirection: 'row', marginVertical: 8 }}>
				<Button
					mode="contained"
					loading={loading}
					disabled={loading}
					onPress={create}
				>
					{loading ? 'Creating...' : `Create Secret Key`}
				</Button>
			</View>
		)
	}, [create, loading])

	const renderWarningOrCreate = useCallback(() => {
		if (apiSecret) return null

		console.log('[warning-or-create]', { iamWorking, alreadyDeployed })

		// if (!iamWorking) return renderWarning()
		if (alreadyDeployed) return renderWarning()
		return renderCreate()

		// return !iamWorking || alreadyDeployed ? renderWarning() : renderCreate()
	}, [alreadyDeployed, apiSecret, iamWorking, renderCreate, renderWarning])

	return (
		<View>
			<H3>2.1 Create a Doorman Secret Key</H3>
			<P>
				In order to run our CLI, you will need a{' '}
				<Strong>one-time secret API key</Strong>.
			</P>
			<P>The key you generate will not be shown again.</P>
			<P>
				Any previous deployments will be removed if you create a new secret key.
				You should only do this one time.
			</P>
			{renderWarningOrCreate()}
		</View>
	)
}

export default React.memo(CreateApiSecret)
