import React, { useCallback } from 'react'
import { BillingSchema } from '../schema/billing'
import CardSection from './Card-Section'
import { Colors, Divider } from 'react-native-paper'
import { P, Strong, EM } from '@expo/html-elements'
import { View, Text, StyleSheet } from 'react-native'
import { Row } from '@nandorojo/bootstrap'
import { calculateMonthlyRate } from '../utils/monthly-rate'
import numeral from 'numeral'
import PremiumChip from './Premium-Chip'
import moment from 'moment'
import { BillingConstants } from '../constants/billing'
import { numberToCurrency } from '../utils/text-to-currency'

type Props = BillingSchema & { loading: boolean }

const BillingOverview = (props: Props) => {
	const {
		billTo,
		lastUpdated,
		totalSms,
		totalSmsThisMonth,
		uniqueUsers = 0,
		nationalSmsRate,
		internationalSmsRate,
		rateLimit,
		loading,
	} = props

	const plan = uniqueUsers > 1000 ? 'Growth' : 'Startup'
	const allowedUsers = uniqueUsers > 1000 ? '10K' : '1K'

	const isPaidProject = !!billTo

	const content = useCallback(() => {
		return (
			<View>
				<Row justifyContent="space-between" alignItems="center">
					<View>
						<Text style={styles.rowHeader}>Project Type</Text>
					</View>
					<View>
						<PremiumChip isPaidProject={isPaidProject} loading={loading} />
					</View>
				</Row>
				<Divider style={styles.divider} />
				{isPaidProject && (
					<>
						<Row justifyContent="space-between" alignItems="center">
							<View>
								<Text style={styles.rowHeader}>{plan} Plan</Text>
								<EM>Up to {allowedUsers} unique users</EM>
							</View>
							<View>
								<Text>
									{numeral(
										calculateMonthlyRate({ smsCost: 0, uniqueUsers })
									).format('$0')}{' '}
									/ mo
								</Text>
							</View>
						</Row>

						<Divider style={styles.divider} />
					</>
				)}
				<Row justifyContent="space-between" alignItems="center">
					<View>
						<Text style={styles.rowHeader}>SMS sent this month</Text>
					</View>
					<View>
						<Text>{totalSmsThisMonth}</Text>
					</View>
				</Row>
				<Divider style={styles.divider} />
				<Row justifyContent="space-between" alignItems="center">
					<View>
						<Text style={styles.rowHeader}>SMS sent all-time</Text>
					</View>
					<View>
						<Text>{totalSms}</Text>
					</View>
				</Row>
			</View>
		)
	}, [
		allowedUsers,
		isPaidProject,
		loading,
		plan,
		totalSms,
		totalSmsThisMonth,
		uniqueUsers,
	])

	return (
		<CardSection
			hideArrow
			open
			title="Overview"
			icon="md-cash"
			color={Colors.green400}
			// right={() =>
			// 	lastUpdated ? (
			// 		<Text style={styles.right}>
			// 			Updated {moment(lastUpdated).calendar()}
			// 		</Text>
			// 	) : null
			// }
		>
			{content()}
		</CardSection>
	)
}

export default React.memo(BillingOverview)

const styles = StyleSheet.create({
	row: {
		marginVertical: 8,
	},
	divider: {
		marginVertical: 16,
	},
	rowHeader: {
		fontWeight: 'bold',
	},
	right: {
		marginRight: 16,
	},
})
