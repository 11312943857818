import { useFuego } from '@nandorojo/fuego'
import { Billing } from '../api/billing'
import { useMemo } from 'react'
import { FireDoc } from '../types/document'
import { BillingSchema } from '../schema/billing'

export const useBilling = ({
	publicProjectId,
}: {
	publicProjectId: string
}) => {
	const { data, ...response } = useFuego<FireDoc<BillingSchema<true>>>({
		path: new Billing({ id: publicProjectId }).path,
		listen: true,
	})
	const parsedData = useMemo(
		() => data && Billing.parseForRender(data as FireDoc<BillingSchema<true>>),
		[data]
	)
	return { data: parsedData, ...response }
}
