import React from 'react'
import { View } from 'react-native'
import { colors } from '../style/colors'
import { P, Strong, H3 } from '@expo/html-elements'
import { useTimingTransition, bInterpolate } from 'react-native-redash'
import Animated from 'react-native-reanimated'
import { Button } from 'react-native-paper'

type Props = {
	onNavigateToBilling?: () => void
	isPaidAccount: boolean
	loading: boolean
	onNavigateToTestNumbers: () => void
}

const BillingNotice = (props: Props) => {
	const {
		onNavigateToTestNumbers,
		onNavigateToBilling,
		isPaidAccount,
		loading,
	} = props

	// const opacity = useTimingTransition(!isPaidAccount && !loading, {
	// 	duration: 100,
	// })

	if (isPaidAccount || loading) return null
	return (
		<Animated.View
			style={{
				backgroundColor: colors().red.shadowColor,
				padding: 16,
				borderRadius: 8,
				marginBottom: 16,
				// opacity: !isPaidAccount,
				// transform: [{ translateY: bInterpolate(opacity, -4, 0) }],
			}}
		>
			<H3 style={{ marginTop: 0 }}>Free test mode</H3>
			<P style={{ margin: 0 }}>
				🚨 This project is in test mode.<Strong></Strong> This means it can only
				use{' '}
				<Strong onPress={onNavigateToTestNumbers} style={{ color: 'green' }}>
					test phone numbers
				</Strong>
				.
			</P>
			<P>
				💰 <Strong>Add a payment method</Strong> to start sending real text
				messages (and to get rid of this annoying banner 😇).
			</P>
			{!!onNavigateToBilling && (
				<View style={{ flexDirection: 'row' }}>
					<Button mode="contained" color="black" onPress={onNavigateToBilling}>
						Add a payment method
					</Button>
				</View>
			)}
		</Animated.View>
	)
}

export default React.memo(BillingNotice)
