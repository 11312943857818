import { fuego } from '../firebase/fuego'
import { SmartOmit } from '../types/smart-omit'
import { DeveloperSchema } from '../schema/developer'
import { ProjectSchema } from '../schema/project'

// conditional types for typescript help
// https://artsy.github.io/blog/2018/11/21/conditional-types-in-typescript/
// 😱fuck type script for the way this code looks

type ActionOptions =
	| 'createDeveloper'
	| 'createApiSecret'
	| 'createProject'
	| 'addPaymentMethod'
	| 'requestSetupIntentId'
	| 'cancelSubscription'
	| 'verifyIamsComplete'
type ActionCreator<A extends ActionOptions> = { action: A }

// See  https://artsy.github.io/blog/2018/11/21/conditional-types-in-typescript/
// this lets us change ({ action: string, ... }) to (action, { ... }) dynamically
type ExcludeActionKey<K> = K extends 'action' ? never : K
type ExcludeActionField<A> = { [K in ExcludeActionKey<keyof A>]: A[K] }
type ExtractActionParameters<A, T> = A extends { action: T }
	? ExcludeActionField<A>
	: never

type CreateDeveloper = ActionCreator<'createDeveloper'> &
	SmartOmit<DeveloperSchema, 'lastUpdated' | 'phoneNumber' | 'createdAt'>

type CreateApiSecret = ActionCreator<'createApiSecret'> & {
	publicProjectId: string
}

type CreateProject = ActionCreator<'createProject'> &
	Pick<ProjectSchema, 'firebaseProjectId' | 'internalName' | 'publicName'>

type RequestSetupIntent = ActionCreator<'requestSetupIntentId'> & {
	publicProjectId: string
}

type AddPaymentMethod = ActionCreator<'addPaymentMethod'> & {
	publicProjectId: string
	paymentMethod: string
}

type CancelSubscription = ActionCreator<'cancelSubscription'> & {
	publicProjectId: string
}

type VerifyIamsComplete = ActionCreator<'verifyIamsComplete'> & {
	publicProjectId: string
}

type Request =
	| CreateApiSecret
	| CreateDeveloper
	| CreateProject
	| RequestSetupIntent
	| AddPaymentMethod
	| CancelSubscription
	| VerifyIamsComplete

type ResponseModel<Res = {}> = Res & {
	message?: string
	success: boolean
}

type Response<A extends ActionOptions> = A extends 'createApiSecret'
	? ResponseModel<{ apiSecret: string }>
	: A extends 'createProject'
	? ResponseModel<
			Pick<ProjectSchema, 'publicName' | 'internalName'> & {
				publicProjectId: string
			}
	  >
	: A extends 'requestSetupIntentId'
	? ResponseModel<{ setupIntentId: string }>
	: A extends 'verifyIamsComplete'
	? ResponseModel<{
			/**
			 * Is this deployment already working? If `true`, means IAMs are successfuly set up. **Not** the same as the `success` field being true.
			 */
			isWorking: boolean
	  }>
	: ResponseModel

export class Server {
	static endpoint =
		'https://sending-messages-for-doorman.herokuapp.com/phoneLogic'
	static async post<A extends ActionOptions>(
		action: A,
		body: ExtractActionParameters<Request, A>
	): Promise<Response<A>> {
		const token = await fuego.auth().currentUser?.getIdToken()
		return fetch(Server.endpoint, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				...body,
				action,
				token,
			}),
		}).then(r => r.json())
	}
}
