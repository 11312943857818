import React, { useCallback } from 'react'
import { BillingMonthSchema } from '../../schema/billing-month'
import { FlatList } from 'react-native'
import BillingMonthItem from './Item'
import { FireDoc } from '../../types/document'

type Schema = FireDoc<BillingMonthSchema>

type Props = {
	billingMonths: Schema[]
	loading: boolean
}

const keyExtractor = (month: Schema) => month.id

const BillingMonths = (props: Props) => {
	const { billingMonths } = props
	const renderItem = useCallback(({ item }: { item: Schema }) => {
		return <BillingMonthItem {...item} />
	}, [])
	return (
		<>
			<FlatList
				renderItem={renderItem}
				data={billingMonths}
				keyExtractor={keyExtractor}
			/>
		</>
	)
}

export default React.memo(BillingMonths)
