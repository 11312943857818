import { colors } from '../../../../style/colors'
import { ProjectStackScreens } from '.'
import { DefaultTheme, Colors } from 'react-native-paper'

type Feature = {
	title: string
	icon: string
	color: string
	shadowColor: string
}

type ID = 'Install' | 'Billing' | 'Debugger' | 'Test Numbers' | 'Errors'

export const ProjectStackFeatures: {
	[key in ID]: Feature
} = {
	Install: {
		title: 'Install',
		icon: 'ios-code-download',
		color: '#17694c',
		shadowColor: '#CBF4C9',
	},
	Billing: {
		title: 'Billing',
		icon: 'ios-wallet',
		color: '#5357B3',
		shadowColor: '#D7EBFF',
	},
	// {
	// 	title: 'API Keys',
	// 	id: 'keys',
	// 	icon: 'ios-key',
	// 	color: '#B5466B',
	// 	shadowColor: '#FDE2DD',
	// },
	Debugger: {
		title: 'SMS History',
		icon: 'ios-bug',
		color: DefaultTheme.colors.primary,
		shadowColor: Colors.purple100,
	},
	'Test Numbers': {
		title: 'Test Numbers',
		icon: 'ios-phone-portrait',
		color: colors().yellow.color,
		shadowColor: colors().yellow.shadowColor,
	},
	Errors: {
		title: 'Logs',
		icon: 'ios-alert',
		color: colors().red.color,
		shadowColor: colors().red.shadowColor,
	},
}

export const ProjectStackFeaturesArray = Object.keys(ProjectStackFeatures).map(
	id => ({
		id,
		...ProjectStackFeatures[id as keyof ProjectStackScreens],
	})
)
