import { FireDoc } from '../types/document'
import { BillingSchema } from '../schema/billing'
import { Doc } from './doc'

export class Billing {
	static collection = 'billing'

	public id: string
	constructor({ id }: { id: string }) {
		this.id = id
	}

	static parseForRender({
		createdAt,
		lastUpdated,
		...billing
	}: FireDoc<BillingSchema<true>>): FireDoc<BillingSchema> {
		return {
			...billing,
			createdAt: createdAt?.toDate(),
			lastUpdated: lastUpdated?.toDate(),
		}
	}
	get path() {
		return `${Billing.collection}/${this.id}`
	}
}
