import Constants from 'expo-constants'
import { empty } from '../utils/empty'
import { Screens, ProjectSubScreens, Events } from './types'

declare global {
	interface Window {
		analytics: any
	}
}

function trackScreen<Screen extends Screens>(
	properties: { screen: Screen } & Screen extends 'Project'
		? { subScreen: ProjectSubScreens }
		: {}
) {
	return trackWithProperites({ event: 'View Screen', properties })
}
function trackWithProperites({
	event,
	properties = empty.object,
}: {
	event: Events
	properties?: object | { phoneNumber: string }
}) {
	window.analytics.track(event, {
		...properties,
		sessionId: Constants.sessionId,
	})
}

export const analytics = {
	trackWithProperites,
	trackScreen,
}
