import React from 'react'
import { FireDoc } from '../../types/document'
import { BillingMonthSchema } from '../../schema/billing-month'
import { Divider, DefaultTheme, Colors } from 'react-native-paper'
import { Row, Col } from '@nandorojo/bootstrap'
import { View, Text, StyleSheet } from 'react-native'
import { H3, H4 } from '@expo/html-elements'
import { numberToCurrency } from '../../utils/text-to-currency'
import moment from 'moment'

type Props = FireDoc<BillingMonthSchema>

const BillingMonthItem = (props: Props) => {
	const {
		id,
		totalSms,
		smsCost,
		nationalSms,
		nationalSmsRate,
		internationalSms,
		internationalSmsRate,
		lastUpdated,
		testSms,
	} = props
	return (
		<View style={styles.container}>
			<Divider />
			<Row
				style={[styles.row, { marginHorizontal: 8 }]}
				justifyContent="space-between"
				alignItems="center"
			>
				<View>
					<H3 style={styles.header}>{moment(id).format('MMMM YYYY')}</H3>
				</View>
				<View style={styles.totalCostContainer}>
					<Text style={styles.totalCost}>{numberToCurrency(smsCost)}</Text>
					<Text>Total</Text>
				</View>
			</Row>
			<Row style={styles.row}>
				<Col style={styles.col}>
					<Text style={styles.count}>{nationalSms}</Text>
					<H4 style={styles.rowHeader}>National SMS</H4>
					{!!nationalSmsRate && (
						<Text>{numberToCurrency(nationalSmsRate)} per</Text>
					)}
				</Col>
				<Col style={styles.col}>
					<Text style={styles.count}>{internationalSms}</Text>
					<H4 style={styles.rowHeader}>International SMS</H4>
					{!!internationalSmsRate && (
						<Text>{numberToCurrency(internationalSmsRate)} per</Text>
					)}
				</Col>

				<Col style={styles.col}>
					<Text style={styles.count}>{testSms}</Text>
					<H4 style={styles.rowHeader}>Test SMS</H4>
					<Text style={styles.free}>Free!</Text>
				</Col>
			</Row>
		</View>
	)
}

export default React.memo(BillingMonthItem)

const styles = StyleSheet.create({
	container: {
		marginBottom: 16,
	},
	header: { marginTop: 0, marginBottom: 8 },
	rowHeader: { marginVertical: 8 },
	count: {
		textAlign: 'center',
	},
	col: {
		alignItems: 'center',
	},
	free: {
		color: Colors.green600,
	},
	totalCost: {
		color: Colors.deepPurpleA400,
		fontWeight: 'bold',
	},
	totalCostContainer: {
		alignItems: 'flex-end',
	},
	row: {
		margin: 8,
		marginTop: 16,
	},
})
