import { Doc } from './doc'
import { Project } from './project'
import { AuthHistorySchema } from '../schema/authHistory'
import { FireDoc } from '../types/document'

export class AuthHistory {
	static collection = 'authHistory'
	public id: string
	constructor({ id }: { id: string }) {
		this.id = id
	}

	get path() {
		return `${Project.collection}/${this.id}/${AuthHistory.collection}`
	}

	static parseForRender(
		authHistory: FireDoc<AuthHistorySchema<true>>
	): FireDoc<AuthHistorySchema<false>> {
		return {
			...authHistory,
			createdAt: authHistory.createdAt.toDate(),
			delivered: authHistory.delivered?.toDate() ?? null,
			queued: authHistory.queued?.toDate() ?? null,
			sent: authHistory.sent?.toDate() ?? null,
			verified: authHistory.verified?.toDate() ?? null,
		}
	}
}
