import React, { useMemo } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useResponsiveFontSize } from '../../hooks/use-responsive-font-size.web'
import { useSetupIntentId } from './use-stripe-client'
import { useAddStripePaymentMethod } from './use-add-stripe-payment-method'
import Loader from '../Loader'
import { CardView } from 'react-native-credit-card-input'
import { View, StyleSheet } from 'react-native'
import { colors } from '../../style/colors'
import { useTimingTransition, bin } from 'react-native-redash'
import Animated from 'react-native-reanimated'
import { H3, P } from '@expo/html-elements'
import { Colors } from 'react-native-paper'

const useOptions = () => {
	const fontSize = useResponsiveFontSize()
	const options = useMemo(
		() => ({
			style: {
				base: {
					fontSize,
					color: '#424770',
					letterSpacing: '0.025em',
					fontFamily: 'Source Code Pro, monospace',
					'::placeholder': {
						color: '#aab7c4',
					},
				},
				invalid: {
					color: '#9e2146',
				},
			},
		}),
		[fontSize]
	)

	return options
}

const CardForm = ({
	publicProjectId,
	isPaidProject,
}: {
	publicProjectId: string
	isPaidProject: boolean
}) => {
	const stripe = useStripe()
	const options = useOptions()
	const { setupIntent, loadingInitially } = useSetupIntentId({
		publicProjectId,
	})
	const {
		loading: addingMethod,
		success,
		handleSubmit,
		error,
	} = useAddStripePaymentMethod({
		publicProjectId,
		setupIntent,
	})
	const justSubscribed = !!(success && isPaidProject)
	const transition = useTimingTransition(justSubscribed, {
		duration: 200,
	})

	return (
		<form onSubmit={handleSubmit} className="stripe-form">
			<label>
				<View>
					<Animated.View
						style={{ ...StyleSheet.absoluteFillObject, opacity: transition }}
					>
						<H3>{`You're now a premium Doorman user!`}</H3>
					</Animated.View>
					<Animated.View style={{ opacity: bin(!success) }}>
						<CardElement options={options} />
					</Animated.View>
				</View>
			</label>
			<View>
				<button
					type="submit"
					disabled={!stripe || addingMethod || loadingInitially || success}
					style={{
						backgroundColor: error
							? Colors.redA700
							: success
							? colors().green.color
							: undefined,
					}}
				>
					{!!error
						? 'Error. Please try again'
						: !!justSubscribed
						? 'Added! 💰🥂'
						: loadingInitially
						? 'Loading...'
						: addingMethod
						? 'Adding...'
						: isPaidProject
						? 'Change Payment Method'
						: 'Add Card & Upgrade'}
				</button>
				{!!error && <P style={{ color: Colors.redA700 }}>{error}</P>}
			</View>
		</form>
	)
}

export default CardForm
