import React from 'react'
import { View } from 'react-native'
import { SmartOmit } from '../types/smart-omit'
import { Ionicons } from '@expo/vector-icons'

type Props = {
	color: string
	size: number
	children: React.ReactNode
}

export default function Circle(props: Props) {
	const { color, size, children } = props
	return (
		<View
			style={{
				backgroundColor: color || undefined,
				height: size,
				width: size,
				borderRadius: size / 2,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{children}
		</View>
	)
}
const Icon = ({
	icon,
	iconColor,
	...props
}: SmartOmit<Props, 'children'> & { icon: string; iconColor: string }) => {
	return (
		<Circle {...props}>
			<Ionicons name={icon} size={props.size * (25 / 40)} color={iconColor} />
		</Circle>
	)
}
Circle.Icon = Icon
