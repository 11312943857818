import React from 'react'
import { Text, StyleSheet } from 'react-native'
import { Colors } from 'react-native-paper'
import Animated from 'react-native-reanimated'
import { useTimingTransition } from 'react-native-redash'

type Props = {
	isPaidProject: boolean
	loading?: boolean
}

const PremiumChip = (props: Props) => {
	const { isPaidProject, loading } = props

	if (loading || !isPaidProject) return null

	return (
		<>
			<Text
				style={[
					styles.chip,
					{
						backgroundColor: !isPaidProject
							? Colors.redA700
							: Colors.deepPurpleA400,
					},
				]}
			>
				{isPaidProject ? 'Premium' : 'Testing'}
			</Text>
		</>
	)
}

export default React.memo(PremiumChip)

const styles = StyleSheet.create({
	chip: {
		color: Colors.white,

		paddingVertical: 4,
		paddingHorizontal: 8,
		borderRadius: 16,
		marginLeft: 8,
		fontSize: 12,
		fontWeight: 'bold',
	},
})
