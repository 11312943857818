import React, { useCallback } from 'react'
import {
	ProjectStackFeaturesArray,
	ProjectStackFeatures,
} from './project-stack-features'
import { StyleSheet, View, Text, Linking } from 'react-native'
import Circle from '../../../../components/Circle'
import {
	TouchableOpacity,
	TouchableWithoutFeedback,
} from 'react-native-gesture-handler'
import { ProjectStackScreens } from '.'
import DeployedChip from '../../../../components/DeployedChip'
import { Divider, Colors, DefaultTheme, Chip } from 'react-native-paper'
import { A } from '@expo/html-elements'
import { useAnalyticsProject } from '../../../../analytics/hooks/use-analytics-screen'
import { colors } from '../../../../style/colors'

type Props = {
	/**
	 * Which screen is active, by ID.
	 */
	active: keyof typeof ProjectStackFeatures
	onSelectFeature: (id: keyof ProjectStackScreens) => void
	isWorking: boolean
	loading: boolean
	iamWorking: boolean
}

const ProjectSideBar = (props: Props) => {
	const {
		active,
		onSelectFeature,
		isWorking = false,
		loading,
		iamWorking,
	} = props

	useAnalyticsProject(active)

	const renderLinks = useCallback(
		() =>
			ProjectStackFeaturesArray.map(
				({ id: screenId, color, icon, shadowColor, title }) => {
					const isActive = screenId === active
					const iconColor = isActive ? shadowColor : color
					const iconBackground = isActive ? color : shadowColor
					const textColor = isActive ? color : undefined
					const chipBackground = isActive ? shadowColor : undefined

					return (
						<Item
							key={screenId}
							{...{
								color,
								iconColor,
								iconBackground,
								chipBackground,
								icon,
								isActive,
								onPress: () =>
									onSelectFeature(screenId as keyof ProjectStackScreens),
								textColor,
								title,
							}}
						/>
					)
				}
			),
		[active, onSelectFeature]
	)

	return (
		<View style={styles.container}>
			<View style={[styles.chip, { opacity: loading ? 0 : 1 }]}>
				<DeployedChip isWorking={isWorking} />
				{!!isWorking && !iamWorking && (
					<Chip
						style={{
							backgroundColor: colors().yellow.shadowColor,
							marginTop: 8,
						}}
					>
						⚠️ IAMs not ready
					</Chip>
				)}
			</View>
			<Divider style={{ marginVertical: 16 }} />
			{renderLinks()}
			{
				// 	<A href="https://docs.doorman.cool" target="_blank">
				// 	<Item
				// 		title="Docs"
				// 		icon="ios-log-in"
				// 		onPress={() => null}
				// 		iconColor={DefaultTheme.colors.primary}
				// 		iconBackground={Colors.purple100}
				// 		isActive={false}
				// 	/>
				// </A>
			}
		</View>
	)
}

const Item = ({
	onPress,
	chipBackground,
	icon,
	iconColor,
	iconBackground,
	textColor,
	isActive,
	title,
}: {
	onPress: () => void
	chipBackground?: string
	icon: string
	iconColor: string
	iconBackground: string
	textColor?: string
	isActive: boolean
	title: string
}) => {
	return (
		<TouchableWithoutFeedback onPress={onPress}>
			<View accessibilityRole="link" style={styles.chip}>
				<View
					style={[
						styles.chipWrapper,
						{
							backgroundColor: chipBackground,
						},
					]}
				>
					<Circle.Icon
						icon={icon}
						iconColor={iconColor}
						size={40}
						color={iconBackground}
					/>
					<Text
						style={[
							styles.text,
							{
								color: textColor,
								fontWeight: isActive ? 'bold' : 'normal',
							},
						]}
					>
						{title}
					</Text>
				</View>
			</View>
		</TouchableWithoutFeedback>
	)
}

export default React.memo(ProjectSideBar)

const styles = StyleSheet.create({
	container: {
		marginRight: 32,
		minWidth: 200,
		marginTop: 32,
		paddingTop: 32,
	},
	chip: {
		padding: 8,
		margin: 8,
	},
	chipWrapper: {
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: 26,
	},
	text: {
		paddingLeft: 16,
	},
})
