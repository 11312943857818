import { FireDoc } from '../types/document'
import { Doc } from './doc'
import { Billing } from './billing'
import { BillingMonthSchema } from '../schema/billing-month'

export class BillingMonth {
	static collection = 'months'
	public id: string
	constructor({ id }: { id: string }) {
		this.id = id
	}

	static parseForRender({
		createdAt,
		lastUpdated,
		...billing
	}: FireDoc<BillingMonthSchema<true>>): FireDoc<BillingMonthSchema> {
		return {
			...billing,
			createdAt: createdAt?.toDate(),
			lastUpdated: lastUpdated?.toDate(),
		}
	}
	path({ publicProjectId }: { publicProjectId: string }) {
		return `${Billing.collection}/${publicProjectId}/${BillingMonth.collection}/${this.id}`
	}
	static getCollectionPath({ publicProjectId }: { publicProjectId: string }) {
		return `${Billing.collection}/${publicProjectId}/${BillingMonth.collection}`
	}
}
