import { Server } from './server'

export class Payment {
	static requestIntent({ publicProjectId }: { publicProjectId: string }) {
		return Server.post('requestSetupIntentId', {
			publicProjectId,
		})
	}
	static addMethod({
		publicProjectId,
		paymentMethod,
	}: {
		publicProjectId: string
		paymentMethod: string
	}) {
		return Server.post('addPaymentMethod', {
			publicProjectId,
			paymentMethod,
		})
	}
}
