import { DeveloperSchema } from '../schema/developer'
import { useReducer, useCallback, useState } from 'react'
import { Developer } from '../api/developer'
import { fuego } from '../firebase/fuego'
import { empty } from '../utils/empty'
import { analytics } from '../analytics'

type State = Pick<DeveloperSchema, 'displayName' | 'useCase' | 'referredBy'>

type Action = {
	payload: string
	type: 'UpdateDisplayName' | 'UpdateUseCase' | 'UpdateReferredBy'
}

const initialState: State = {
	displayName: '',
	useCase: '',
	referredBy: '',
}

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'UpdateDisplayName':
			return {
				...state,
				displayName: action.payload,
			}
		case 'UpdateReferredBy':
			return {
				...state,
				referredBy: action.payload,
			}
		case 'UpdateUseCase':
			return {
				...state,
				useCase: action.payload,
			}
		default:
			throw new Error(
				'🥶 useCreateDeveloper hook reducer error: Tried to call an action that does not exist.'
			)
	}
}

type Options = {
	onCreateSuccess: () => void
}
/**
 * Hook that can be used to edit or create a user.
 */
export const useCreateDeveloper = (
	{ onCreateSuccess }: Partial<Options> = empty.object
) => {
	const [{ displayName, referredBy, useCase }, dispatch] = useReducer(
		reducer,
		initialState
	)

	const [loading, setLoading] = useState(false)

	const create = useCallback(async () => {
		setLoading(true)
		const { currentUser } = fuego.auth()
		console.log('should create user...', { currentUser })
		if (currentUser) {
			analytics.trackWithProperites({ event: 'Create Developer' })
			try {
				await new Developer({ id: currentUser.uid }).create({
					displayName,
					referredBy,
					useCase,
				})
				onCreateSuccess?.()
			} catch (e) {
				setLoading(false)
				console.error(
					'error in use create developer hook function create.',
					e,
					{ displayName }
				)
			}
		}
	}, [displayName, referredBy, useCase, onCreateSuccess])

	const edit = useCallback(async () => {
		// filter empty strings out

		const { currentUser } = fuego.auth()
		if (currentUser) {
			setLoading(true)
			const data = { displayName, referredBy, useCase }
			const userDataToUpdate: Partial<typeof data> = {}
			Object.keys(data).forEach(dataType => {
				// if a string is empty, it doesn't get added
				if (data[dataType as keyof typeof data]) {
					userDataToUpdate[dataType as keyof typeof data] =
						data[dataType as keyof typeof data]
				}
			})
			await new Developer({ id: currentUser.uid }).edit({
				displayName,
				referredBy,
				useCase,
			})
			setLoading(false)
		} else {
			console.error(
				'cannot edit an inexistent user. see useCreateDeveloper hook -> edit'
			)
		}
	}, [displayName, referredBy, useCase])

	const updateDisplayName = useCallback(
		(name: string) => dispatch({ type: 'UpdateDisplayName', payload: name }),
		[dispatch]
	)
	const updateReferredBy = useCallback(
		(name: string) => dispatch({ type: 'UpdateReferredBy', payload: name }),
		[dispatch]
	)
	const updateUseCase = useCallback(
		(name: string) => dispatch({ type: 'UpdateUseCase', payload: name }),
		[dispatch]
	)
	return {
		displayName,
		referredBy,
		useCase,
		loading,
		readyToCreate: !!(displayName && referredBy && useCase),
		updateUseCase,
		updateReferredBy,
		updateDisplayName,
		create,
		edit,
	}
}
