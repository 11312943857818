import React from 'react'
import { Chip } from 'react-native-paper'
import { colors } from '../style/colors'

type Props = {
	isWorking: boolean
}

const DeployedChip = (props: Props) => {
	const { isWorking } = props
	const { green, red } = colors()
	const deploymentStatus = isWorking ? '🥳 Deployed' : '⛔️ Not deployed'
	return (
		<>
			<Chip
				// selected
				selectedColor="white"
				style={{
					backgroundColor: isWorking ? green.color : red.color,
				}}
			>
				{deploymentStatus}
			</Chip>
		</>
	)
}

export default DeployedChip
