import { ProjectSchema } from '../schema/project'
import { useReducer, useCallback, useState, useRef, useEffect } from 'react'
import { fuego } from '../firebase/fuego'
import { Project } from '../api/project'
import { analytics } from '../analytics'

type State = Pick<
	ProjectSchema,
	'publicName' | 'internalName' | 'firebaseProjectId'
>

type Action = {
	payload: string
	type: 'UpdatePublicName' | 'UpdateInternalName' | 'UpdateFirebaseProjectId'
}

const initialState: State = {
	publicName: '',
	internalName: '',
	firebaseProjectId: '',
}

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'UpdatePublicName':
			return {
				...state,
				publicName: action.payload,
			}
		case 'UpdateInternalName':
			return {
				...state,
				internalName: action.payload,
			}
		case 'UpdateFirebaseProjectId':
			return {
				...state,
				firebaseProjectId: action.payload,
			}
		default:
			throw new Error(
				'🥶 useCreateProject hook reducer error: Tried to call an action that does not exist.'
			)
	}
}
type Unpromisify<T> = T extends Promise<infer R> ? R : T

type Props = {
	onCreateSuccess: (
		info: Unpromisify<ReturnType<typeof Project.create>>
	) => void
}

export const useCreateProject = (props: Props) => {
	const [data, dispatch] = useReducer(reducer, initialState)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<null | string>(null)

	const successCallback = useRef(props?.onCreateSuccess)
	useEffect(() => {
		successCallback.current = props?.onCreateSuccess
	})

	const { firebaseProjectId, internalName, publicName } = data

	const updatePublicName = (payload: string) =>
		dispatch({ type: 'UpdatePublicName', payload })
	const updateFirebaseProjectId = (payload: string) =>
		dispatch({ type: 'UpdateFirebaseProjectId', payload })
	const updateInternalName = (payload: string) =>
		dispatch({ type: 'UpdateInternalName', payload })

	const create = useCallback(async () => {
		setLoading(true)
		const { currentUser } = fuego.auth()
		if (currentUser) {
			analytics.trackWithProperites({ event: 'Create Project' })
			try {
				const response = await Project.create({
					firebaseProjectId,
					internalName,
					publicName,
				})
				successCallback.current?.(response)
			} catch (e) {
				if (`${e}`.includes('already exists')) alert(`Error: ${e}`)
				// setError(e)
				console.error('error in use create developer hook function create.', e)
			}
		}
		setLoading(false)
	}, [firebaseProjectId, internalName, publicName])

	return {
		firebaseProjectId,
		internalName,
		publicName,
		updatePublicName,
		updateInternalName,
		updateFirebaseProjectId,
		create,
		loading,
	}
}
